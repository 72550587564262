import React, { Component } from 'react';
import { Table, Image, Button, Grid, Label,Message,Icon,Modal,Header,Loader,Dimmer,Input,Popup,Dropdown,Breadcrumb,Form,Sidebar,Segment,
    Checkbox,DropdownDivider,DropdownHeader,Radio,TextArea} from 'semantic-ui-react';
import { recipesActions,ingredientsActions,supplierActions,paymentActions,alertActions } from '../_actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SideLeftUncoverOrders from './SidebarOrders';
import {toaster_dispatch,toaster_info,optimizeDecimalPart,history,computeVolumeUnitFactor,computeWeightUnitFactor
    ,typeYield,isFloat,isNumeric,trim,isPack,isBoxPack,GUEST,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER,isEmpty,mgrams,grams,cliters,mliters,/*,isWeight,isVolume,kgrams,cliters,mliters,liters*/} from '../_helpers';
import {FormattedMessage,FormattedNumber,FormattedDate,FormattedTime,injectIntl} from 'react-intl';
import OutsideClickHandler from 'react-outside-click-handler';
import img_performance from '../Assets/performance.png'
import {styles} from './Styles';
import img_order from '../Assets/receipt.png'
import img_bag from '../Assets/shopping-bag.png';


class OrdersListDetails extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { prevRecipe : props.recipes,
                       prevIngredient : props.ingredients,
                       recipesData: [],
                       ingredientsList:[],
                       currency : userIn.user.currency,
                       activeModalPremium: false,
                       activeModalDelete: false,
                       activeModalStatus: false,
                       activeModalReduceStockAvailable: false,
                       activeModalDestock: false,
                       activeModalRestock: false,
                       activeModalIngredientDelete: null,
                       activeModalRename: false,
                       activeModalIngredientRound:false,
                       activeModalNote: false,
                       newQuantity:0,
                       //newUnit:'',
                       editCell: false,
                       active_role: userIn.user.role,
                       filtering: false,
                       supplierSelected: null,
                       orderName: "",
                       withEmailSupplier: false,
                       withEmailCosts: 1,
                       orderNote: "",
                     }
        props.dispatch(paymentActions.getCustomerMembership());
        props.dispatch(recipesActions.getOrder(props.match.params.orderId));
        props.dispatch(ingredientsActions.getExtraSuppliers());
        props.dispatch(paymentActions.getSubscriptionState());
        props.dispatch(supplierActions.getAll());

        this.setOrderStatus=this.setOrderStatus.bind(this);
        this.reduceStockQuantity=this.reduceStockQuantity.bind(this);
        this.addStockQuantity=this.addStockQuantity.bind(this);
        this.destockQuantity=this.destockQuantity.bind(this);
        this.createSuppliersOrders=this.createSuppliersOrders.bind(this);
        this.deleteOrder=this.deleteOrder.bind(this);
        this.deleteIngredientOrder=this.deleteIngredientOrder.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.filterIngredientsSupplierList2=this.filterIngredientsSupplierList2.bind(this);
        this.filterNameList=this.filterNameList.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if((props.recipes!==state.prevRecipe)&&(props.recipes.order)){
            let order = props.recipes.order;
            let aggRecipes = [];
            let aggIngredients = [];
 
             if (order && order.use_ingredients == true){
                for (let i of order.ingredients){
                    let supplier = null
                    if (i.extra_supplier_used){
                        if (i.extra_supplier){
                            supplier = {id:i.extra_supplier.supplier.id, 
                                        name: i.extra_supplier.supplier.name }
                        }
                       
                    }else{
                        if (i.ingredient.supplier){
                            supplier = {id:i.ingredient.supplier.id, 
                                        name: i.ingredient.supplier.name }
                        }else if (i.ingredient.supplier__name){
                            supplier = {id:i.ingredient.supplier__id, 
                                        name: i.ingredient.supplier__name }
                        }
                    }
                    i.supplier = supplier
                    aggIngredients.push({id: i.ingredient.id, data:i, count:i.unit})
                }

                if (order.items && order.items.length > 0){
                    for (let l of order.items){
                        if (l.item){
                            aggRecipes.push({item:l.item, unit:l.unit, is_nb_recipes:l.is_recipes_unit})
                        }
                    }
                }
                let numberSuppliers = 0;
                const suppliers_lists = aggIngredients.reduce((sublist, item) => {

                    let use_supplier = null
                    if (item.data.supplier && item.data.supplier.name && item.data.supplier.name!=""){
                        use_supplier = item.data.supplier.name
                    }
    
                    if (use_supplier && use_supplier!="" && !sublist[use_supplier]) {
                        sublist[use_supplier] = [];
                    }else if (!sublist["undefined"]){
                        sublist["undefined"] = [];
                    }
    
                    if (use_supplier && use_supplier!="") {
                        sublist[use_supplier].push(item);
                    }else {
                        sublist["undefined"].push(item)
                    }

                    return sublist;
                  }, {})
    
                let new_suppliers_lists = []
                for (let [supplier_name, ingredients_list] of Object.entries(suppliers_lists)) {
                    let ingredients_list = ingredients_list.sort(function (a, b) {
                            let a_normalized = a.data.ingredient.name.normalize('NFKD').replace (/[\u0300-\u036F]/g, "")
                            let b_normalized = b.data.ingredient.name.normalize('NFKD').replace (/[\u0300-\u036F]/g, "")
                            return a_normalized.toLowerCase().localeCompare(b_normalized.toLowerCase()); 
                    })
                    new_suppliers_lists.push({supplier_name,ingredients_list});
                    new_suppliers_lists.sort(function (a, b) {
                        return a.supplier_name.toLowerCase().localeCompare(b.supplier_name.toLowerCase()); 
                    })
                    if (ingredients_list.length > 0) numberSuppliers +=1;
    
                }
                return { prevRecipe : props.recipes,
                         recipesData: aggRecipes,
                         ingredientsList: aggIngredients,
                         suppliers_lists:new_suppliers_lists,
                         numberSuppliers:numberSuppliers,
                         orderName: order.ref_name,
                         orderNote: order.note,}
            }
            else if (order && order.items && order.items.length > 0){
                for (let l of order.items){
                        if (l.item){
                            aggRecipes.push({item:l.item, unit:l.unit})
                            for (let i of l.item.ingredients){
                                if (i.ingredient){
                                  let factor = 1;
                                  if (i.quantity_type == 'weight'){
                                        factor = computeWeightUnitFactor('g',i.quantity_unit);
                                        i.quantity_value *=  factor
                                        i.quantity_unit = 'g'
                                        i.quantity =  i.quantity_value + i.quantity_unit
                                   }
                                   else if (i.quantity_type == 'volume'){
                                        factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                        i.quantity_value *=  factor
                                        i.quantity_unit = 'l'
                                        i.quantity =  i.quantity_value + i.quantity_unit
                                  }
                                  let count = Number(l.unit).toFixed(3)
                                  var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                  if (index === -1) {
                                      i.supplier = null
                                      aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                  }else {
                                      //Update when ingredient already exists
                                      let old_i = aggIngredients[index].data;
                                      let old_count = aggIngredients[index].count;
                                      let new_i = i;
                                      if (i.quantity_type == old_i.quantity_type)
                                      {
                                         new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                         new_i.quantity_value = new_i.quantity_value.toString();
                                         new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                         new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count; 
                                         new_i.supplier = null
                                         aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                      } 
                                  }
                                }else{
                                  //TO CHECK WHY ONE TIME
                                  console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
                                }
                            }
                            for (let r of l.item.subrecipes){
                                if (r.subrecipe){
                                    let yield_count = r.subrecipe.yield_count
                                    let yield_units = r.subrecipe.yield_units
                                    let quantity_value = r.quantity_value
                                    let factor = 1;
                                    let percent = 1;

                                    let recipe_type = typeYield(yield_units);
                                    let recipe_factor = 1;
    
                                    if (recipe_type == 'weight'){
                                        recipe_factor = computeWeightUnitFactor('g', yield_units);
                                        recipe_factor *= Number(yield_count);
                                    }
                                    else if (recipe_type == 'volume'){
                                        recipe_factor = computeVolumeUnitFactor('l', yield_units);
                                        recipe_factor *= Number(yield_count);
                                    }else{
                                        recipe_factor = Number(yield_count);
                                    }
    
                                    if (recipe_factor < 0) recipe_factor = 1;

                                    for (let i of r.subrecipe.ingredients){
                                        if (i.ingredient){
                                            if (i.quantity_type == 'weight'){
                                                factor = computeWeightUnitFactor('g',i.quantity_unit);
                                                i.quantity_value *=  factor
                                                i.quantity_unit = 'g'
                                                i.quantity =  i.quantity_value + i.quantity_unit
                                            }
                                            else if (i.quantity_type == 'volume'){
                                                factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                                i.quantity_value *=  factor
                                                i.quantity_unit = 'l'
                                                i.quantity =  i.quantity_value + i.quantity_unit
                                            }

                                            /* if (factor){
                                                factor *= Number(quantity_value)
                                                factor /= Number(yield_count)
                                            } */
                                            //percent = Number(quantity_value)/Number(yield_count)
                                            percent = Number(quantity_value)/recipe_factor
                                            let count = Number(l.unit*percent).toFixed(3)

                                            var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                            if (index === -1) {
                                                i.supplier = null
                                                aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                            }else {
                                                //Update when ingredient already exists
                                                let old_i = aggIngredients[index].data;
                                                let old_count = aggIngredients[index].count;
                                                let new_i = i;
                                                if (i.quantity_type == old_i.quantity_type)
                                                {   
                                                   new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                                   new_i.quantity_value = new_i.quantity_value.toString();
                                                   new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                                   new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count;
                                                   new_i.supplier = null
                                                   aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                                } 
                                            }
                                        }
                                    }
                                    for (let sub of r.subrecipe.subrecipes){
                                        if (sub.subrecipe){
                                            let yield_count = sub.subrecipe.yield_count
                                            let yield_units = sub.subrecipe.yield_units
                                            let quantity_value = sub.quantity_value
                                            let factor= 1;
                                            let percent2=1;

                                            if (recipe_type == 'weight'){
                                                recipe_factor = computeWeightUnitFactor('g', yield_units);
                                                recipe_factor *= Number(yield_count);
                                            }
                                            else if (recipe_type == 'volume'){
                                                recipe_factor = computeVolumeUnitFactor('l', yield_units);
                                                recipe_factor *= Number(yield_count);
                                            }else{
                                                recipe_factor = Number(yield_count);
                                            }
    
                                            if (recipe_factor < 0) recipe_factor = 1;

                                            for (let i of sub.subrecipe.ingredients){
                                                if (i.ingredient){
                                                    if (i.quantity_type == 'weight'){
                                                        factor = computeWeightUnitFactor('g',i.quantity_unit);
                                                        i.quantity_value *=  factor
                                                        i.quantity_unit = 'g'
                                                        i.quantity =  i.quantity_value + i.quantity_unit
                                                    }
                                                    else if (i.quantity_type == 'volume'){
                                                        factor = computeVolumeUnitFactor('l',i.quantity_unit);
                                                        i.quantity_value *=  factor
                                                        i.quantity_unit = 'l'
                                                        i.quantity =  i.quantity_value + i.quantity_unit
                                                    }
/*                                                     if (factor){
                                                        factor *= Number(quantity_value)
                                                        factor /= Number(yield_count)
                                                    } */
                                                    percent2 = Number(quantity_value)/recipe_factor
                                                    //percent2 = Number(quantity_value)/Number(yield_count)
                                                    let count = Number(l.unit*percent2*percent).toFixed(2)
                                                    var index = aggIngredients.findIndex(x => x.id == i.ingredient.id)
                                                    if (index === -1) {
                                                        i.supplier = null
                                                        aggIngredients.push({id: i.ingredient.id, data:i, count:count})
                                                    }else {
                                                        //Update when ingredient already exists
                                                        let old_i = aggIngredients[index].data;
                                                        let old_count = aggIngredients[index].count;
                                                        let new_i = i;
                                                        if (i.quantity_type == old_i.quantity_type)
                                                        {
                                                           new_i.quantity_value = old_i.quantity_value*old_count + i.quantity_value*count;
                                                           new_i.quantity_value = new_i.quantity_value.toString();
                                                           new_i.quantity = new_i.quantity_value + old_i.quantity_unit;
                                                           new_i.item_cost = old_i.item_cost*old_count + i.item_cost*count;
                                                           new_i.supplier = null
                                                           aggIngredients[index] = {id: i.ingredient.id, data:new_i, count:1}
                                                        } 
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                }  
                //console.log(aggRecipes);
                //console.log(aggIngredients);
                return { prevRecipe : props.recipes,
                         recipesData: aggRecipes,
                         ingredientsList: aggIngredients,
                         orderName: order.ref_name,
                         orderNote: order.note,
                         /*order: order*/}
            }
        }
        if((props.ingredients!==state.prevIngredient)&&(props.ingredients.items)){
            return { prevIngredient : props.ingredients,
                     availableIngredientsList: props.ingredients.items };
        }
        return null;
    }


    computeQuantityWithIngredientUnit(item, onlyValue=false){
        let unitPart = "";
        let valuePart = 0;

        if (item.data.quantity_value) valuePart = item.data.quantity_value
        if (item.data.quantity_unit) unitPart = item.data.quantity_unit

        if (item.data.quantity_type == 'volume'){

            if (item.data.quantity_unit != item.data.ingredient.unit){
                let ifactor = computeVolumeUnitFactor(item.data.ingredient.unit,item.data.quantity_unit);
                valuePart = item.data.quantity_value * ifactor
                unitPart = item.data.ingredient.unit
            }
            // Apply number of items
            valuePart*= Number(item.count);

/*             for (let unit of unitVolume ){
              if (unitPart === unit){
                if ((unitPart === 'ml')||(unitPart === 'milliliter')||(unitPart === 'milliliters')){
                    if (valuePart >= Number(1000)){
                        unitPart = 'L';
                        valuePart /= Number(1000);
                    }
                }
                else if ((unitPart === 'cl')||(unitPart === 'centiliter')||(unitPart === 'centiliters')){
                    if (valuePart >= Number(100)){
                        unitPart = 'L';
                        valuePart /= Number(100);
                    }
                }
                break;
              }
            } */
          }else if (item.data.quantity_type == 'weight'){

             if (item.data.quantity_unit != item.data.ingredient.unit){
                let ifactor = computeWeightUnitFactor(item.data.ingredient.unit,item.data.quantity_unit);
                 valuePart = item.data.quantity_value * ifactor
                 unitPart = item.data.ingredient.unit
             }

            // Apply number of items
            valuePart*= Number(item.count);

/*             for (let unit of unitWeight ){
              if (unitPart === unit){
                if ((unitPart === 'g')||(unitPart === 'gram')||(unitPart === 'grams')){
                    if (valuePart >= Number(1000)){
                        unitPart = 'kg';
                        valuePart /= Number(1000);
                    }
                }
                else if (unitPart === 'mg'){
                    if (valuePart >= Number(1000)){
                        unitPart = 'g';
                        valuePart /= Number(1000);
                    }
                }
                break;
              } 
           } */
         }else if (item.data.quantity_type == 'quantity'){
            // Apply number of items
            valuePart*= Number(item.count);
         }
        

        if (onlyValue){
            return optimizeDecimalPart(valuePart,3);
        }

        if (item.data.quantity_type == 'quantity'){
            let is_pack = isPack(item.data.quantity_unit)
            let is_box_pack = isBoxPack(item.data.quantity_unit)
            if (is_pack || is_box_pack){
                let unitPack = ""
                let pack_units = item.data.ingredient.pack_units
                let type = typeYield(item.data.ingredient.unit)
                let is_volume_ingredient = (type == 'volume')
                let is_weight_ingredient = (type == 'weight')

                if (is_weight_ingredient) unitPack = item.data.ingredient.purchase_weight
                else if (is_volume_ingredient) unitPack = item.data.ingredient.purchase_volume
                else  unitPack = item.data.ingredient.purchase_quantity

                if (item.data.extra_supplier_used  == true && item.data.extra_supplier){
                        if (is_pack|| is_box_pack){
                            if (is_weight_ingredient) unitPack = item.data.extra_supplier.purchase_weight
                            else if (is_volume_ingredient) unitPack = item.data.extra_supplier.purchase_volume
                            else unitPack = item.data.extra_supplier.purchase_quantity
                            pack_units = item.data.extra_supplier.pack_units
                        }
                }
                if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
                    if (item.data.ingredient.default_supplier == false){
                        for (let s of item.data.ingredient.extra_suppliers){
                            if (s.default_supplier == true){
                                if (is_pack|| is_box_pack){
                                    if (is_weight_ingredient) unitPack = s.purchase_weight
                                    else if (is_volume_ingredient) unitPack = s.purchase_volume
                                    else unitPack = s.purchase_quantity
                                    pack_units = s.pack_units
                                }
                                break
                            }
                        }
                    }
                }
                if (is_box_pack){
                    return optimizeDecimalPart(valuePart,3) +  ' x  [' + pack_units + ' x ' + unitPack + ']';
                }
                else if (is_pack){
                    return optimizeDecimalPart(valuePart,3) + ' x ' + unitPack;
                }

            }else{
                return optimizeDecimalPart(valuePart,3) + ' ' + unitPart;
            }
        }

        let valuePartI18n = <FormattedNumber value={valuePart.toFixed(3)}/>

        return <FormattedMessage id="orderslistdetails.compute.quantity.result"
                                 defaultMessage='{valuePart} {unitPart}' 
                                 values={{valuePart: valuePartI18n,
                                          unitPart:  unitPart}}/>
        //return valuePart.toFixed(2) + ' ' + unitPart;
    }


    computeTotalCost(ingredientsList){
        let cost = 0;
        ingredientsList.map((item,index) => {
            cost+=(item.data.item_cost*item.count);
            return item;
        });
        return cost.toFixed(2);
    }

    computeTotalCostWithTax(ingredientsList){
        let cost = 0;
        let tax = 1;
        ingredientsList.map((item,index) => {
            tax = 1 + Number(item.data.ingredient.tax)
            cost+=(item.data.item_cost*item.count*tax);
            return item;
        });
        return cost.toFixed(2);
    }

    computeTotalTax(ingredientsList){
        let cost = 0;
        let tax = 1;
        ingredientsList.map((item,index) => {
            tax =  Number(item.data.ingredient.tax)
            cost+=(item.data.item_cost*item.count*tax);
            return item;
        });
        return cost.toFixed(2);
    }
    
    computeSupplier(item){
  /*       let default_supplier = item.data.ingredient.supplier && item.data.ingredient.supplier.name
        if (item.data.ingredient.default_supplier == false){
            for (let s of item.data.ingredient.extra_suppliers){
                if (s.default_supplier == true){
                    default_supplier = s.supplier.name
                    break
                }
            }
        } */
        let default_supplier = item.data.ingredient.supplier && item.data.ingredient.supplier.name
        if (item.data.extra_supplier_used == true && item.data.extra_supplier){
            default_supplier = item.data.extra_supplier.supplier && item.data.extra_supplier.supplier.name
        }
        if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
            if (item.data.ingredient.default_supplier == false){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.default_supplier == true){
                        default_supplier = s.supplier.name
                        break
                    }
                }
            }
        }
        return  default_supplier;
    }

    computePurchaseUnit(item){
        const{currency} = this.state;
        let is_pack = isPack(item.data.quantity_unit)
        let is_box_pack = isBoxPack(item.data.quantity_unit)
        let is_quantity = item.data.quantity_type == 'quantity'
        let type = typeYield(item.data.ingredient.unit)
        let is_volume_ingredient = (type == 'volume')
        let is_weight_ingredient = (type == 'weight')
        let is_quantity_ingredient = (type == 'quantity')
 /*        let default_price_per_unit = item.data.ingredient.price_per_unit
        if (item.data.ingredient.default_supplier == false){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.default_supplier == true){
                        default_price_per_unit = s.price_per_unit
                        break
                    }
                }
        }  */

        let purchase_pack = ""
        let pack_units =  item.data.ingredient.pack_units
        if (is_weight_ingredient) purchase_pack = item.data.ingredient.purchase_weight
        else if (is_volume_ingredient) purchase_pack = item.data.ingredient.purchase_volume
        else  purchase_pack = item.data.ingredient.purchase_quantity

        let default_price_per_unit = item.data.ingredient.price_per_unit
        if (is_box_pack){
            default_price_per_unit = item.data.ingredient.price
        }
        if (is_pack){
            default_price_per_unit = item.data.ingredient.price / item.data.ingredient.pack_units
        }
        if (item.data.extra_supplier_used  == true && item.data.extra_supplier){
                default_price_per_unit = item.data.extra_supplier.price_per_unit
                pack_units =  item.data.extra_supplier.pack_units
                if (is_pack || is_box_pack){
                    if (is_box_pack){
                        default_price_per_unit = item.data.extra_supplier.price
                    }
                    if (is_pack){
                        default_price_per_unit = item.data.extra_supplier.price / item.data.extra_supplier.pack_units
                    }
                    if (is_weight_ingredient) purchase_pack = item.data.extra_supplier.purchase_weight
                    else if (is_volume_ingredient) purchase_pack = item.data.extra_supplier.purchase_volume
                    else purchase_pack = item.data.extra_supplier.purchase_quantity
                }
        }
        if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
            if (item.data.ingredient.default_supplier == false){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.default_supplier == true){
                        default_price_per_unit = s.price_per_unit
                        pack_units =  s.pack_units
                        if (is_pack || is_box_pack){
                            if (is_box_pack){
                                default_price_per_unit = s.price
                            }
                            if (is_pack){
                                default_price_per_unit = s.price / s.pack_units
                            }
                            if (is_weight_ingredient) purchase_pack = s.purchase_weight
                            else if (is_volume_ingredient) purchase_pack = s.purchase_volume
                            else purchase_pack = s.purchase_quantity
                        }
                        break
                    }
                }
            }
        }

        let price_per_unit = <FormattedNumber value={default_price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>

        if (is_quantity){
            if (is_box_pack){
                if (is_quantity_ingredient) {
                    return <FormattedMessage id="orderslistdetails.quantity.box_pack.result.quantity"
                                            defaultMessage='{valuePart} / {pack_units} x {unitPart} units' 
                                            values={{pack_units: pack_units,
                                                    valuePart: price_per_unit,
                                                    unitPart:  purchase_pack}}/>
                }else{
                    return <FormattedMessage id="orderslistdetails.quantity.box_pack.result"
                                        defaultMessage='{valuePart} / {pack_units} x {unitPart}' 
                                        values={{pack_units: pack_units,
                                                valuePart: price_per_unit,
                                                unitPart:  purchase_pack}}/>
                }
            }
            else if (is_pack){
                    if (is_quantity_ingredient) {
                        return <FormattedMessage id="orderslistdetails.quantity.pack.result.quantity"
                                                defaultMessage='{valuePart} / {unitPart} units' 
                                                values={{valuePart: price_per_unit,
                                                        unitPart:  purchase_pack}}/>
                    }else{
                        return <FormattedMessage id="orderslistdetails.quantity.pack.result"
                                            defaultMessage='{valuePart} / {unitPart}' 
                                            values={{valuePart: price_per_unit,
                                                    unitPart:  purchase_pack}}/>
                    }
            }else{

                return <FormattedMessage id="orderslistdetails.quantity.unit.result"
                                            defaultMessage='{valuePart} / unit' 
                                            values={{valuePart: price_per_unit}}/>
            }
        }else{
                let new_unit = item.data.ingredient.unit
                if (grams.includes(item.data.ingredient.unit))
                {
                    price_per_unit = <FormattedNumber value={Number(default_price_per_unit) * 1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                    new_unit = 'Kg'
                }else if (mgrams.includes(item.data.ingredient.unit))
                {
                    price_per_unit = <FormattedNumber value={Number(default_price_per_unit) * 1e6} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                    new_unit = 'Kg'
                }
                else if (cliters.includes(item.data.ingredient.unit))
                {
                    price_per_unit = <FormattedNumber value={Number(default_price_per_unit) * 100} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                    new_unit = 'L'
                }
                else if (mliters.includes(item.data.ingredient.unit))
                {
                    price_per_unit = <FormattedNumber value={Number(default_price_per_unit) * 1000} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                    new_unit = 'L'
                }
                
                return <FormattedMessage id="orderslistdetails.notquantity.unit.result"
                                        defaultMessage='{valuePart} / {unitPart}' 
                                        values={{valuePart: price_per_unit,
                                                 unitPart:   new_unit}}/>
        
        }
    }


    computePurchaseUnitOnly(item){
        let is_pack = isPack(item.data.quantity_unit)
        let is_box_pack = isBoxPack(item.data.quantity_unit)
        let is_quantity = item.data.quantity_type == 'quantity'
        let type = typeYield(item.data.ingredient.unit)
        let is_volume_ingredient = (type == 'volume')
        let is_weight_ingredient = (type == 'weight')
        //let is_quantity_ingredient = (type == 'quantity')

        let purchase_pack = ""
        let pack_units =  item.data.ingredient.pack_units
        if (is_weight_ingredient) purchase_pack = item.data.ingredient.purchase_weight
        else if (is_volume_ingredient) purchase_pack = item.data.ingredient.purchase_volume
        else purchase_pack = item.data.ingredient.purchase_quantity

        if (item.data.extra_supplier_used  == true && item.data.extra_supplier){
            if (is_pack || is_box_pack){
                    pack_units =  item.data.extra_supplier.pack_units
                    if (is_weight_ingredient) purchase_pack = item.data.extra_supplier.purchase_weight
                    else if (is_volume_ingredient) purchase_pack = item.data.extra_supplier.purchase_volume
                    else purchase_pack = item.data.extra_supplier.purchase_quantity
                }
        }
        if (item.data.extra_supplier_used  == true && !item.data.extra_supplier){
            if (item.data.ingredient.default_supplier == false){
                for (let s of item.data.ingredient.extra_suppliers){
                    if (s.default_supplier == true){
                        if (is_pack || is_box_pack){
                            pack_units =  s.pack_units
                            if (is_weight_ingredient) purchase_pack = s.purchase_weight
                            else if (is_volume_ingredient) purchase_pack = s.purchase_volume
                            else purchase_pack = s.purchase_quantity
                        }
                        break
                    }
                }
            }
        }

        if (is_quantity){
            if (is_box_pack){
                return <FormattedMessage id="orderslistdetails.unitpart.boxpack.result"
                                         defaultMessage='{pack_units} x {unitPart}' 
                                         values={{unitPart:  purchase_pack,
                                                  pack_units:pack_units}}/>
            }
            else if (is_pack){
/*                 if (is_quantity_ingredient) {
                    return <FormattedMessage id="orderslistdetails.unitpart.pack.result.quantity"
                                            defaultMessage='{unitPart} units' 
                                            values={{unitPart:  purchase_pack}}/>
                }else{ */

                    return <FormattedMessage id="orderslistdetails.unitpart.pack.result"
                                             defaultMessage='x {unitPart}' 
                                             values={{unitPart:  purchase_pack}}/>
                //}

            }/* else{

                return <FormattedMessage id="orderslistdetails.unitpart.quantity.result"
                                            defaultMessage='unit'/>
            } */
        }else{
                return <FormattedMessage id="orderslistdetails.unitpart.notquantity.result"
                                            defaultMessage='{unitPart}' 
                                            values={{unitPart:  item.data.ingredient.unit}}/>
        }
    }

    generateDocBackend(event, isPdf, isWithPrices){
        event.preventDefault();
        const { ingredientsList } = this.state;
        const {customer,order,intl} = this.props;
        let user = null;
        let membership = null;
            
        if (customer){
            membership = customer.membership;
            user = customer.user;
        }
    
        if (membership && user &&
            ((membership.membership_type=="Entreprise") || (membership.membership_type=="Professional")|| (user.bypass==true))) 
        {
            if (ingredientsList){
                let properties = {
                    id: order.id,
                    items: ingredientsList,
                    name: order.ref_name,
                    withCosts: isWithPrices,
                }
                if (isPdf){
                    this.props.dispatch(recipesActions.renderOrder(properties)); 
                }else{
                    this.props.dispatch(recipesActions.renderOrderCsv(order.id, order.ref_name,isWithPrices)); 
                }
                toaster_info(intl.formatMessage({ id: 'downloadrecipe.toaster.message.title' }),
                             intl.formatMessage({ id: 'downloadrecipe.toaster.message.content' }))   
                
            } 
        }else{
           this.setState({ activeModalPremium: true});
        }  
    }

    deleteClickHandler (event) {
        event.preventDefault();
        this.setState({ activeModalDelete: true});
    }; 

    noteClickHandler (event) {
        event.preventDefault();
        const { order } = this.props; 
        this.setState({ activeModalNote: true});
        this.setState({ note: order.note });
    };

    onNoteUpdate = (e) => {
        e.preventDefault();
        this.setState({ note: e.target.value });
    }

    orderStatusClickHandler (event) {
        event.preventDefault();
        const { order } = this.props;
        if (order && order.state == 'ongoing'){
            this.setState({ activeModalStatus: true});
        }else if (order && order.state == 'sent'){
            this.setState({ activeModalRestock: true});
        } 
    }; 

    reduceStockClickHandler (event) {
        event.preventDefault();
        this.setState({ activeModalReduceStockAvailable: true});
    };
    
    deleteIngredientClickHandler (event,index,item) {
        event.preventDefault();
        this.setState({ activeModalIngredientDelete: index});
        this.setState({ item_order: item});
    };

    destockClickHandler (event) {
        event.preventDefault();
        this.setState({ activeModalDestock: true});
    }; 

    deleteOrder(event){
        event.preventDefault();
        const {order} = this.props;
        this.props.dispatch(recipesActions.deleteOrder(order.id));  
    }

    deleteIngredientOrder(event){
        event.preventDefault();
        const {order} = this.props;
        const { item_order } = this.state;
        this.props.dispatch(recipesActions.deleteIngredientOrder(order.id,
                                                                {order_ingredient_id:item_order.data.id,
                                                                      quantity_value:1
                                                                }
                ));  
    }

    roundIngredientClickHandler (event,round_as_supplier) {
        event.preventDefault();
        this.setState({ activeModalIngredientRound: true});
        this.setState({ roundingMode: round_as_supplier});
    };

    roundIngredientsOrder(event,round_as_supplier){
        event.preventDefault();
        const {order} = this.props;
        this.props.dispatch(recipesActions.roundOrderQuantity(order.id,
                                                             {round_as_supplier:round_as_supplier}));  
    }

    renameClickHandler (event) {
        event.preventDefault();
        const { order } = this.props; 
        this.setState({ activeModalRename: true});
        this.setState({ nameSelected: order.ref_name });
    };

    onRenameOrder = (e) => {
        e.preventDefault();
        this.setState({ nameSelected: e.target.value });
    }

    renameOrder (event,index) {
        event.preventDefault();
        const { order } = this.props; 
        let nameSelected = this.state.nameSelected;
        
        if (!isEmpty(nameSelected)){
            nameSelected = nameSelected.slice(0,50);
            this.props.dispatch(recipesActions.updateOrder(order.id,{ref_name:nameSelected},true)); 
            this.setState({ activeModalRename: false});
        }
    }

    updateOrder (event,index) {
        event.preventDefault();
        const { order } = this.props; 
        let note = this.state.note;
        
        if (!isEmpty(note)){
            note = note.slice(0,650);
        }
        this.props.dispatch(recipesActions.updateOrder(order.id,{ref_name:this.state.orderName,note:note},false,false)); 
        this.setState({ activeModalNote: false});
    }

    seeRecipe (event,index) {
        event.preventDefault();
        history.push('/recipedetails/'+index);
    }

    seeIngredient (event,index) {
        event.preventDefault();
        history.push('/ingredientdetails/'+index);
    }



    setOrderStatus(event){
        event.preventDefault();
        const { order } = this.props;
        const { withEmailSupplier,ingredientsList,withEmailCosts } = this.state;
        //console.log(order)
        let status = 'ongoing'
        let updated = false;

        if (order){            
            if (!(order.state)){
                status = 'sent'
                updated = true;  
            }else{
                if (order.state === 'ongoing'){
                    status = 'sent';
                    updated = true;   
                }else if (order.state === 'sent'){
                    status = 'received';
                    updated = true;  
                }
            } 
            //console.log(updated)
            //console.log(status)
            if (updated){
                let neworder = {
                    ref_name:order.ref_name,
                    state:status,
                }
                this.props.dispatch(recipesActions.updateOrder(order.id,neworder)); 

                if (ingredientsList && withEmailSupplier){
                    let parameters = {
                        id:order.id,
                        items: ingredientsList,
                        name: order.ref_name,
                        withCosts: withEmailCosts,
                    }
                    setTimeout(() => { this.props.dispatch(recipesActions.sendOrder(order.id,parameters))}, 300);
               }
            } 
        }

    }


    reduceStockQuantity(event){
        event.preventDefault();
        const { order } = this.props;
        if (order && order.ingredients){
            this.props.dispatch(recipesActions.deduceStockOrder(order.id)); 
            this.setState({ activeModalReduceStockAvailable: false});
        }

    }

    destockQuantity(event){
        event.preventDefault();
        const { order } = this.props;
        if (order && order.ingredients){
            this.props.dispatch(recipesActions.destockOrder(order.id)); 
            this.setState({ activeModalDestock: false});
        }

    }

    addStockQuantity(event){
        event.preventDefault();
        const { order } = this.props;
        if (order && order.ingredients){
            this.props.dispatch(recipesActions.addStockOrder(order.id)); 
            this.setState({ activeModalRestock: false});
        }

    }

    createSuppliersOrders(event){
        event.preventDefault();
        const { order } = this.props;
        if (order && order.ingredients){
            this.props.dispatch(recipesActions.createSuppliersOrders(order.id)); 
        }

    }


   editQuantityHandlerInput (event,index,item) {
        event.preventDefault();
        let quantity = this.computeQuantityWithIngredientUnit(item, true)
        this.setState({ newQuantity: quantity});
        this.setState({ editCell: true});
        this.setState({ editCellIndex: index});
    }; 

/*     editQuantityHandlerInput (event,index,item) {
        event.preventDefault();
        const {intl} = this.props; 
        //let quantity = intl.formatNumber(Number(breakdown.quantity_value).toFixed(2));
        let quantity = optimizeDecimalPart(Number(item.data.quantity_value))
        this.setState({ newQuantity: quantity});
        let relevantUnit

        //this.setState({ menu_ingredient: breakdown.ingredient.id});

        if (this.state.newUnit == ''){
                relevantUnit = item.data.quantity_unit.toLowerCase()
                if (relevantUnit == ''){
                    relevantUnit = intl.formatMessage({ id: 'editmenu.quantity.unit.plural.text' }) 
                }
        }else{
                relevantUnit = this.state.newUnit
        }
        this.setState({ newUnit: relevantUnit});
        this.setState({ editCell: true});
        this.setState({ editCellIndex: index});
    }; */

    
    onNewQuantity = (e,item) => {
        //e.preventDefault();
        //console.log( e.target.value)
        this.setState({ newQuantity: e.target.value});
    }

/*     onNewUnit = (e,data) => {
        //e.preventDefault();
        this.setState({ newUnit: data.value});
    } */

    assertQuantityField(quantity){
        if ((isFloat(quantity) || isNumeric(quantity))
          && (quantity != "")
          && Math.trunc(quantity).toString().length<10)
          return true;
        return false;
    }

    updateQuantityOnBlur (event,order_id,breakdown) {
        event.preventDefault();
        const {newQuantity,ingredientsList,active_role} = this.state;
        const {dispatch} = this.props;
        let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
        //let quantity_origin = this.computeQuantityWithIngredientUnit(breakdown, true)
        //quantity_origin = Number(quantity_origin).toFixed(3) 

        const title = <FormattedMessage id="orderslistdetails.quantity.check.error.title"
                                        defaultMessage='Quantity Update Error'/>

        if (this.assertQuantityField(quantity) /* & quantity!=quantity_origin */){
            if (active_role!= GUEST){
                dispatch(recipesActions.updateOrderQuantity(order_id,{order_ingredient_id:breakdown.data.id,quantity_value:quantity}));
                let is_pack =  isPack(breakdown.data.quantity_unit)
                let is_box_pack =  isBoxPack(breakdown.data.quantity_unit)
                let listUpdated = ingredientsList.map((item,index) => {
                    if (item.data.id == breakdown.data.id){
                        item.data.quantity_value = quantity
                        if (!is_pack && !is_box_pack){
                            item.data.quantity_unit = breakdown.data.ingredient.unit
                        }   
                        if (is_box_pack){
                            item.data.item_cost = Number(breakdown.data.ingredient.price) * Number(quantity)
                        } 
                        else if (is_pack){
                            item.data.item_cost = Number(breakdown.data.ingredient.price)/Number(breakdown.data.ingredient.pack_units) * Number(quantity)
                        }else{
                            item.data.item_cost = Number(breakdown.data.ingredient.price_per_unit) * Number(quantity)
                        }
                        if (breakdown.data.extra_supplier_used == true && breakdown.data.extra_supplier){
                            if (is_box_pack){
                                item.data.item_cost = Number(breakdown.data.extra_supplier.price) * Number(quantity)
                            }
                            else if (is_pack){
                                item.data.item_cost = Number(breakdown.data.extra_supplier.price)/Number(breakdown.data.extra_supplier.pack_units) * Number(quantity)
                            }else{
                                item.data.item_cost = Number(breakdown.data.extra_supplier.price_per_unit) * Number(quantity)
                            }
                        }else if (breakdown.data.extra_supplier_used == true && !breakdown.data.extra_supplier){
                            if (breakdown.data.ingredient.default_supplier == false){
                                    for (let s of breakdown.data.ingredient.extra_suppliers){
                                        if (s.default_supplier == true){
                                           if (is_box_pack){
                                                 item.data.item_cost = Number(s.price) * Number(quantity)
                                           }
                                           else if (is_pack){
                                                item.data.item_cost = Number(s.price) / Number(s.pack_units) * Number(quantity)
                                            }else{
                                                item.data.item_cost = Number(s.price_per_unit) * Number(quantity)
                                            }
                                            break
                                        }
                                    }
                            }
                        }
                        else{
                            if (is_box_pack){
                                item.data.item_cost = Number(breakdown.data.ingredient.price) * Number(quantity)
                            }
                            else if (is_pack){
                                item.data.item_cost = Number(breakdown.data.ingredient.price)/Number(breakdown.data.ingredient.pack_units) * Number(quantity)
                            }else{
                                item.data.item_cost = Number(breakdown.data.ingredient.price_per_unit) * Number(quantity)
                            }
                        }
                        item.data.unit =  Number(1)
                        item.count = Number(1)
                    }
                    return item;
                });
                this.setState({ingredientsList:listUpdated})
                this.setState({ editCell: false });
                //this.setState({ newUnit: '' });
            }else{
                const content =  <FormattedMessage id="orderslistdetails.quantity.privilege.error.message"
                                                   defaultMessage='You do not have Right Privilege'/>
                dispatch(alertActions.error({title:'orderslistdetails.quantity.privilege.error.message',
                                             content:'orderslistdetails.quantity.check.error.title'}));  
                this.setState({ editCell: false });
            }
        }else{
            if (Math.trunc(quantity).toString().length>=9){

                const content =  <FormattedMessage id="orderslistdetails.quantity.check.error.message"
                                                  defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                 dispatch(alertActions.error({title:'orderslistdetails.quantity.check.error.title',
                                             content:'orderslistdetails.quantity.check.error.message'}));   
             }
            this.setState({ editCell: false });
        }
    }


    onIncreaseDecreaseQuantity = (event,breakdown,increase) => {
        event.preventDefault();
        const {ingredientsList,active_role,newQuantity} = this.state;
        const {dispatch} = this.props;

        let quantity = Number(trim( newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
        if (increase){
            quantity++;
        }else{
            if (quantity > 1) quantity--;
        } 
        
        const title = <FormattedMessage id="orderslistdetails.quantity.check.error.title"
                                        defaultMessage='Quantity Update Error'/>

        if (this.assertQuantityField(quantity)/* && quantity!=quantity_origin*/){
                if (active_role!= GUEST){
                        //dispatch(recipesActions.updateOrderQuantity(order.id,{order_ingredient_id:breakdown.data.id,quantity_value:quantity}));
                        let is_pack =  isPack(breakdown.data.quantity_unit)
                        let is_box_pack =  isBoxPack(breakdown.data.quantity_unit)
                        let listUpdated = ingredientsList.map((item,index) => {
                            if (item.data.id == breakdown.data.id){
                                item.data.quantity_value = quantity
                                if (!is_pack && !is_box_pack){
                                    item.data.quantity_unit = breakdown.data.ingredient.unit
                                }
                                if (is_box_pack){
                                    item.data.item_cost = Number(breakdown.data.ingredient.price) * Number(quantity)
                                }    
                                else if (is_pack){
                                    item.data.item_cost = Number(breakdown.data.ingredient.price)/Number(breakdown.data.ingredient.pack_units) * Number(quantity)
                                }else{
                                    item.data.item_cost = Number(breakdown.data.ingredient.price_per_unit) * Number(quantity)
                               }
                                if (breakdown.data.extra_supplier_used == true  && breakdown.data.extra_supplier){
                                    if (is_box_pack){
                                        item.data.item_cost = Number(breakdown.data.extra_supplier.price) * Number(quantity)
                                    } 
                                    else if (is_pack){
                                        item.data.item_cost = Number(breakdown.data.extra_supplier.price)/Number(breakdown.data.extra_supplier.pack_units) * Number(quantity)
                                    }else{
                                        item.data.item_cost = Number(breakdown.data.extra_supplier.price_per_unit) * Number(quantity)
                                    }
                                }else if (breakdown.data.extra_supplier_used == true && !breakdown.data.extra_supplier){
                                    if (breakdown.data.ingredient.default_supplier == false){
                                            for (let s of breakdown.data.ingredient.extra_suppliers){
                                                if (s.default_supplier == true){
                                                   if (is_box_pack){
                                                       item.data.item_cost = Number(s.price) * Number(quantity)
                                                   }
                                                   else if (is_pack){
                                                        item.data.item_cost = Number(s.price)/ Number(s.pack_units) * Number(quantity)
                                                    }else{
                                                        item.data.item_cost = Number(s.price_per_unit) * Number(quantity)
                                                    }
                                                    break
                                                }
                                            }
                                    }
                                }
                                else{
                                    if (is_box_pack){
                                        item.data.item_cost = Number(breakdown.data.ingredient.price) * Number(quantity)
                                    }
                                    else if (is_pack){
                                        item.data.item_cost = Number(breakdown.data.ingredient.price)/ Number(breakdown.data.ingredient.pack_units) * Number(quantity)
                                    }else{
                                        item.data.item_cost = Number(breakdown.data.ingredient.price_per_unit) * Number(quantity)
                                    }
                                }
                                item.data.unit =  Number(1)
                                item.count = Number(1)
                            }
                            return item;
                        });
                        this.setState({ingredientsList:listUpdated})
                        this.setState({ newQuantity:quantity});
                        this.setState({ editCell: true });
                }else{
                        const content =  <FormattedMessage id="orderslistdetails.quantity.privilege.error.message"
                                                           defaultMessage='You do not have Right Privilege'/>
                        dispatch(alertActions.error({title:'orderslistdetails.quantity.privilege.error.message',
                                                     content:'orderslistdetails.quantity.check.error.title'}));  
                    }
        }else{
            if (Math.trunc(quantity).toString().length>=9){
                       const content =  <FormattedMessage id="orderslistdetails.quantity.check.error.message"
                                                         defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                        dispatch(alertActions.error({title:'orderslistdetails.quantity.check.error.title',
                                                    content:'orderslistdetails.quantity.check.error.message'}));   
            }
        }
    }


    updateQuantityOnEnter (event,order_id,breakdown) {
        const {newQuantity,ingredientsList,active_role} = this.state;
        const {dispatch} = this.props;

        if (event.key === 'Enter') {
                let quantity = Number(trim(newQuantity.toString()).replace(/,/g, '.')).toFixed(3);
                //let quantity_origin = this.computeQuantityWithIngredientUnit(breakdown, true)
                //quantity_origin = Number(quantity_origin).toFixed(3)

                const title = <FormattedMessage id="orderslistdetails.quantity.check.error.title"
                                                defaultMessage='Quantity Update Error'/>

                if (this.assertQuantityField(quantity) /* && quantity!=quantity_origin */){

                    if (active_role!= GUEST){
                        dispatch(recipesActions.updateOrderQuantity(order_id,{order_ingredient_id:breakdown.data.id,quantity_value:quantity}));
                        let is_pack =  isPack(breakdown.data.quantity_unit)
                        let is_box_pack =  isBoxPack(breakdown.data.quantity_unit)
                        let listUpdated = ingredientsList.map((item,index) => {
                            if (item.data.id == breakdown.data.id){
                                item.data.quantity_value = quantity
                                if (!is_pack && !is_box_pack){
                                    item.data.quantity_unit = breakdown.data.ingredient.unit
                                }   
                                if (is_box_pack){
                                    item.data.item_cost = Number(breakdown.data.ingredient.price) * Number(quantity)
                                } 
                                else if (is_pack){
                                    item.data.item_cost = Number(breakdown.data.ingredient.price)/Number(breakdown.data.ingredient.pack_units) * Number(quantity)
                                }else{
                                    item.data.item_cost = Number(breakdown.data.ingredient.price_per_unit) * Number(quantity)
                               }
                                if (breakdown.data.extra_supplier_used == true  && breakdown.data.extra_supplier){
                                    if (is_box_pack){
                                        item.data.item_cost = Number(breakdown.data.extra_supplier.price) * Number(quantity)
                                    } 
                                    else if (is_pack){
                                        item.data.item_cost = Number(breakdown.data.extra_supplier.price)/Number(breakdown.data.extra_supplier.pack_units) * Number(quantity)
                                    }else{
                                        item.data.item_cost = Number(breakdown.data.extra_supplier.price_per_unit) * Number(quantity)
                                    }
                                }else if (breakdown.data.extra_supplier_used == true && !breakdown.data.extra_supplier){
                                    if (breakdown.data.ingredient.default_supplier == false){
                                            for (let s of breakdown.data.ingredient.extra_suppliers){
                                                if (s.default_supplier == true){
                                                  if (is_box_pack){
                                                        item.data.item_cost = Number(s.price) * Number(quantity)
                                                    }
                                                   else if (is_pack){
                                                        item.data.item_cost = Number(s.price)/ Number(s.pack_units) * Number(quantity)
                                                    }else{
                                                        item.data.item_cost = Number(s.price_per_unit) * Number(quantity)
                                                    }
                                                    break
                                                }
                                            }
                                    }
                                }
                                else{
                                    if (is_box_pack){
                                        item.data.item_cost = Number(breakdown.data.ingredient.price) * Number(quantity)
                                    }
                                    else if (is_pack){
                                        item.data.item_cost = Number(breakdown.data.ingredient.price)/ Number(breakdown.data.ingredient.pack_units) * Number(quantity)
                                    }else{
                                        item.data.item_cost = Number(breakdown.data.ingredient.price_per_unit) * Number(quantity)
                                    }
                                }
                                item.data.unit =  Number(1)
                                item.count = Number(1)
                            }
                            return item;
                        });
                        this.setState({ingredientsList:listUpdated})
                        this.setState({ editCell: false });
                    }else{
                        const content =  <FormattedMessage id="orderslistdetails.quantity.privilege.error.message"
                                                           defaultMessage='You do not have Right Privilege'/>
                        dispatch(alertActions.error({title:'orderslistdetails.quantity.privilege.error.message',
                                                     content:'orderslistdetails.quantity.check.error.title'}));  
                        this.setState({ editCell: false });
                    }
                }else{
                    if (Math.trunc(quantity).toString().length>=9){
                       const content =  <FormattedMessage id="orderslistdetails.quantity.check.error.message"
                                                         defaultMessage='Quantity field to big (must be less than 10 digits)'/>
                        dispatch(alertActions.error({title:'orderslistdetails.quantity.check.error.title',
                                                    content:'orderslistdetails.quantity.check.error.message'}));   
                    }
                    this.setState({ editCell: false });
                }
        }
    }


    addOrderRows(recipesData){
        const {order} = this.props;
        const {currency} = this.state;
        recipesData  =  recipesData.sort(function (a, b) {
            return a.item.name.toLowerCase().localeCompare(b.item.name.toLowerCase());
        }) 
        //Temporary
        //To manage older orders using units instead of quantities 
        var dateUpdate = new Date(2020,8,19,12,5,0,0);
        //console.log(dateUpdate)
        var dateOrder= new Date(order.created);
        let boolUseUnits = false
        if (dateOrder < dateUpdate){
            boolUseUnits = true
        }
        return recipesData.map((i,index) => {
                return (
                    <Table.Row key={index} >
                        { !boolUseUnits &&  i.is_nb_recipes &&
                        <Table.Cell width={4} textAlign='left'><FormattedNumber value={i.unit} />{' '}<FormattedMessage id="orderslistdetails.quantity.recipe.text"
                                                                                                                                defaultMessage='recipe(s)'/></Table.Cell>}
                       { !boolUseUnits &&  !i.is_nb_recipes &&
                        <Table.Cell width={4} textAlign='left'><FormattedNumber value={i.unit} />{' '}{i.item.yield_units}</Table.Cell>}
                        { boolUseUnits &&
                        <Table.Cell width={4} textAlign='left'><FormattedNumber value={i.unit} /></Table.Cell>}
                        {/* <Table.Cell textAlign='left'><Icon name='book' color='grey'/>{Capitalize(i.item.name)}</Table.Cell> */}
                        <Table.Cell width={5} textAlign='left'><Icon name='food' color='blue'/><a href="#" 
                               onClick={(event) => this.seeRecipe(event,i.item.id)}
                               >{i.item.name}
                            </a>
                        </Table.Cell>
                        <Table.Cell width={5} textAlign='center'><FormattedNumber value={i.item.yield_count} />{' '}{i.item.yield_units}</Table.Cell>
                        <Table.Cell textAlign='right'>
                        {typeYield(i.item.yield_units) == 'quantity' &&
                           <span> 
                               <FormattedNumber value={i.item.cost_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                               {' / '}{i.item.yield_units}</span>
                            }
                        {typeYield(i.item.yield_units) != 'quantity' &&
                            <span><FormattedNumber value={i.item.cost_total} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/>
                            {' / '}<FormattedMessage id="orderslistdetails.quantity.recipe.singular.text"
                                                           defaultMessage='recipe'/></span>
                        }
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    addListRows(ingredientsList){
        const{currency,editCell,editCellIndex,newQuantity} = this.state;
        const{order} = this.props;
        //console.log(ingredientsList)
       ingredientsList  =  ingredientsList.sort(function (a, b) {
            return a.data.ingredient.name.toLowerCase().localeCompare(b.data.ingredient.name.toLowerCase());
        }) 
        return ingredientsList.map((item,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell width={3} textAlign='left' style={{'color': 'black'}} >
                            {item.data.ingredient.name}
                        </Table.Cell>
                        <Table.Cell width={3} textAlign='center'>{item.data.ingredient.supplier && item.data.ingredient.supplier.name}</Table.Cell>

                        {item.data.quantity_type == 'quantity' &&
                        <Table.Cell width={2} textAlign='right'>
                                    {this.computePurchaseUnit(item)}{' / '} 
                                    <FormattedMessage id="orderslistdetails.quantity.unit.text"
                                                     defaultMessage='unit'/></Table.Cell>}
                         {item.data.quantity_type != 'quantity' &&
                        <Table.Cell width={2} textAlign='right'>{this.computePurchaseUnit(item)}{' / '}{item.data.ingredient.unit}</Table.Cell>}
                        
                        {order  && order.state =='received' &&
                         <Table.Cell width={2} textAlign='right'>
                             {this.computeQuantityWithIngredientUnit(item)} 
                          </Table.Cell>
                        }
                        {order  && order.state!='received' &&
                          <Table.Cell width={2} textAlign='right' onClick={(event) => this.editQuantityHandlerInput(event,index,item)}>

                          {(!editCell || (editCell && index != editCellIndex) ) &&
                                <a href='#'>{this.computeQuantityWithIngredientUnit(item)}</a>}
                           {editCell && index == editCellIndex &&
                           <OutsideClickHandler onOutsideClick={(event) => {this.setState({ editCell: false})}}>
                                <div>
                                        <Input type="text"
                                                id="quantity"
                                                value={newQuantity}
                                                onChange={this.onNewQuantity}
                                                onBlur={(event) => this.updateQuantityOnBlur(event,order.id,item)} 
                                                onKeyPress={(event) => this.updateQuantityOnEnter(event,order.id,item)} 
                                                autoFocus
                                                style={{height:'2.5em',minWidth:'5em',maxWidth:'8em', padding:'0em 0.5em 0em 0.5em'}}
                                                //error={submitted && (!this.assertQuantityField())}
                                        />
                                    {' '}{item.data.ingredient.unit}
                                </div>
                            </OutsideClickHandler>
                           }  
                    
                          </Table.Cell>}                 
      {/*                   {item.data.quantity_type == 'quantity' && item.data.ingredient.stock_quantity > 0 && 
                        <Table.Cell width={2} textAlign='center'>{optimizeDecimalPart(Number(item.data.ingredient.stock_quantity))}</Table.Cell>}
                        {item.data.quantity_type == 'quantity' && (!item.data.ingredient.stock_quantity || (item.data.ingredient.stock_quantity == 0)) && 
                        <Table.Cell width={2} textAlign='center'> 0 </Table.Cell>} */}
                        
    {/*                     {item.data.quantity_type != 'quantity' && item.data.ingredient.stock_quantity > 0 && 
                        <Table.Cell width={2} textAlign='center'>{optimizeDecimalPart(Number(item.data.ingredient.stock_quantity))} {item.data.ingredient.stock_unit}</Table.Cell>}
                        {item.data.quantity_type != 'quantity' && (!item.data.ingredient.stock_quantity || (item.data.ingredient.stock_quantity == 0)) && 
                        <Table.Cell width={2} textAlign='center'> 0 {item.data.ingredient.stock_unit} </Table.Cell>} */}

                        {order  && order.state!='received' &&
                          <Table.Cell width={2} textAlign='right'>{optimizeDecimalPart(Number(item.data.ingredient.stock_quantity))} {item.data.ingredient.stock_unit}</Table.Cell>
                        }
                        <Table.Cell width={3} textAlign='right'>
                             <FormattedNumber value={(item.data.item_cost*item.count).toFixed(2)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                            {/*  {intl.formatMessage({id:'currency.symbol'})} */}</Table.Cell>
                        <Table.Cell width={1} textAlign='right'>
                           {order  && order.state!='received' &&
                                  <Popup trigger={<Icon color='grey' name='trash alternate outline' style={{cursor:'pointer'}}
                                                         onClick={(event) => this.deleteIngredientClickHandler(event,index,item)}/>
                                                 }
                                        content={<FormattedMessage id="orderslistdetails.popup.delete.text"
                                                                   defaultMessage='Delete'/>}
                                        inverted
                                        size='small' 
                                        /> }    
                        </Table.Cell>
                        {order && order.state!='received' &&
                            <Modal id={index}
                                   open={this.state.activeModalIngredientDelete === index}
                                   dimmer='default' 
                                   size='tiny' 
                                   onClose={this.hideModal}
                                   style={styles.Modal}
                                   closeIcon>
                                <Header icon='trash alternate outline' content={<FormattedMessage id="orderslistdetails.modal.ingredient.deletion.title"
                                                                                                    defaultMessage='Deletion confirmation'/>} />
                                <Modal.Content>
                                    <p><FormattedMessage id="orderslistdetails.modal.ingredient.deletion.message"
                                                         defaultMessage='Really sure to delete {ingredient} from order {order} ?'
                                                         values={{order: order.ref_name,
                                                                 ingredient : item.data.ingredient.name}} /></p>
                                </Modal.Content>
                                <Modal.Actions>
                                       <Button style={styles.ButtonGrey}//basic color='grey' 
                                               onClick={this.hideModal}>
                                            <FormattedMessage id="orderslistdetails.modal.ingredient.deletion.button.no"
                                                              defaultMessage='No'/>
                                        </Button>
                                        <Button style={styles.ButtonRed}//basic color='teal' 
                                                labelPosition='right' 
                                                content={<FormattedMessage id="orderslistdetails.modal.ingredient.deletion.button.yes"
                                                                          defaultMessage='Yes'/> }
                                                icon='checkmark' 
                                                onClick={(event) =>this.deleteIngredientOrder(event,order.id)}/>
                                </Modal.Actions>
                            </Modal> }
                     </Table.Row>
                );
        });
    }

/*     getRelevantOptions = (unit) => {
        const {intl} = this.props;
        let options = []
        let emptyUnit = intl.formatMessage({ id: 'editmenu.quantity.unit.plural.text' }) 

        unit =  unit.toLowerCase()

        if (isWeight(unit)){
                 if (mgrams.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'},
                              {key:'kg',text:'kg',value:'kg'}]
                 }else if (grams.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'kg',text:'kg',value:'kg'}]
                 }else if (kgrams.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'g',text:'g',value:'g'}]
                 }
                 else{
                    options= [{key:unit,text:unit,value:unit}]
                 }   
            }
        else if (isVolume(unit)){
                if (cliters.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'ml',text:'ml',value:'ml'},
                              {key:'l',text:'l',value:'l'}]
                 }else if (mliters.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'l',text:'l',value:'l'}]
                }else if (liters.includes(unit)){
                    options= [{key:unit,text:unit,value:unit},
                              {key:'cl',text:'cl',value:'cl'},
                              {key:'ml',text:'ml',value:'ml'}]
                 }else{
                    options= [{key:unit,text:unit,value:unit}]
                 }     

        }else{
                options= [{key:emptyUnit,text:emptyUnit,value:emptyUnit}]
        }
        //console.log(options)
        return options;
    } */

    addListRowsPerSupplier(){
        const{currency,editCell,editCellIndex,newQuantity,suppliers_lists,numberSuppliers/*,newUnit*/} = this.state;
        const{order} = this.props;
        let new_supplier = false;
        let supplier_title = "";

        let nb_ingredients = 0;
        let computeGblIndex = 0; 

        return suppliers_lists.map((elem,indexSupplier) => { 
            new_supplier = true
/*             let ingredientsList  =  elem.ingredients_list.sort(function (a, b) {
                return a.data.ingredient.name.toLowerCase().localeCompare(b.data.ingredient.name.toLowerCase()); 
            })  */
            let ingredientsList = elem.ingredients_list.sort(function (a, b) {
                   if ((!a.data.ingredient.category) && (!b.data.ingredient.category)) return 0; 
                   if (!a.data.ingredient.category)  return 1;
                   if (!b.data.ingredient.category)  return -1;
                   if (a.data.ingredient.category && a.data.ingredient.category.name && b.data.ingredient.category && b.data.ingredient.category.name ){
                        
                        let a_normalized = a.data.ingredient.category.name.normalize('NFKD').replace (/[\u0300-\u036F]/g, "")
                        let b_normalized = b.data.ingredient.category.name.normalize('NFKD').replace (/[\u0300-\u036F]/g, "")
                        return a_normalized.toLowerCase().localeCompare(b_normalized.toLowerCase()); 
                    }
            })

            if (indexSupplier>0){
                computeGblIndex = nb_ingredients;
            }

            return ingredientsList.map((item,indexSupplierElem) => {
                
                 if (indexSupplierElem > 0){
                    computeGblIndex += 1 ;
                 }

                let index = computeGblIndex
                let tax =  Number(item.data.ingredient.tax)
                             
                if (new_supplier == true && numberSuppliers > 1){
                    nb_ingredients +=  ingredientsList.length;
                    new_supplier = false 
                    if (elem.supplier_name == 'undefined'){
                        supplier_title = <FormattedMessage id="orderslistdetails.list.ingredient.supplier.divider.notdefined"
                                                     defaultMessage='No supplier'/>
                    }else{
                        supplier_title = elem.supplier_name

                    }      
                    return ([
                            <Table.Row style={{'background-color':'#f5f5f5'}}>
                                                <Table.Cell style={{color:'#00b3b3'}}> {supplier_title}</Table.Cell>
                                                {order  && order.state =='received' &&<Table.Cell colspan='4'/>}
                                                {order  && order.state !='received' &&<Table.Cell colspan='5'/>}
                                                <Table.Cell textAlign='center' style={{color:'#00b3b3'}}> 
                                                    <FormattedMessage id="orderslistdetails.list.ingredient.supplier.subtotal.text"
                                                                      defaultMessage='Total :'/>{'  '}
                                                    <FormattedNumber value={this.computeTotalCost(ingredientsList)} 
                                                                     style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                </Table.Cell>
                                                <Table.Cell/> 
                              </Table.Row>,
                              <Table.Row key={index} >
{/*                                 <Table.Cell width={1} textAlign='center' style={{'color': 'black'}} >
                                {1 && <Input type='checkbox'
                                                            //onChange={(e) => this.selectAllSelectList(e,items)}
                                                            style={{'transform': 'scale(1.0)'}}
                                                            checked={0}/>}
                                </Table.Cell> */}
                              <Table.Cell width={3} textAlign='left' style={{'color': 'black'}} >
                              {item.data.ingredient.category && 
                                     <span style={{'color':'#b5b1b1','font-size':'11px'}}><i>{' '}{item.data.ingredient.category.name}{''}</i></span>}
                                <div>
                                {item.data.ingredient.name.length >= 45 && 
                                   <a href="#" 
                                   onClick={(event) => this.seeIngredient(event,item.data.ingredient.id)}
                                   >{item.data.ingredient.name.substring(0,45) + '..'}
                                </a>}
                                {item.data.ingredient.name.length < 45 && 
                                    <a href="#" 
                                   onClick={(event) => this.seeIngredient(event,item.data.ingredient.id)}
                                   >{item.data.ingredient.name}
                                </a>}
                                </div>
                              </Table.Cell>
                              <Table.Cell width={2} textAlign='center'>{this.computeSupplier(item)}{/* {item.data.ingredient.supplier && item.data.ingredient.supplier.name} */}</Table.Cell>

                              <Table.Cell width={2} textAlign='center'>{this.computePurchaseUnit(item)}</Table.Cell>
                              
                              {order  && order.state =='received' &&
                              <Table.Cell width={2} textAlign='center'>
                                  {this.computeQuantityWithIngredientUnit(item)}
                              </Table.Cell>
                              }
                             {order  && order.state!='received' &&
                              <Table.Cell width={2} textAlign='center' onClick={(event) => this.editQuantityHandlerInput(event,index,item)}>
                           
                            {(!editCell || (editCell && index != editCellIndex) ) &&
                                     <a href='#'>{this.computeQuantityWithIngredientUnit(item)}</a>}
                              {editCell && index == editCellIndex &&
                                 <OutsideClickHandler  
                                       onOutsideClick={(event) => this.updateQuantityOnBlur(event,order.id,item)}>
                                      <div >   
                                       <Icon name='minus circle' size='large'
                                              style={{cursor:'pointer'}} 
                                              onClick={(event) =>this.onIncreaseDecreaseQuantity(event,item,false)} /> 
                                        <Icon name='plus circle'  size='large'
                                              style={{cursor:'pointer',padding:'0em 1em 0em 0em'}} 
                                              onClick={(event) => this.onIncreaseDecreaseQuantity(event,item,true)}/>
                                        <Input type="text"
                                                      id="quantity"
                                                      value={newQuantity}
                                                      onChange={this.onNewQuantity}
                                                      //onBlur={(event) => {console.log('onBlur'),this.updateQuantityOnBlur(event,order.id,item)}} 
                                                      onKeyPress={(event) => this.updateQuantityOnEnter(event,order.id,item)} 
                                                      autoFocus
                                                      //style={{height:'2.5em',minWidth:'5em',maxWidth:'8em', padding:'0em 0.5em 0em 0.5em'}}
                                                      style={{height:'2.6em',minWidth:'6em',maxWidth:'6em'}}
                                                      label={{ basic: false, content: this.computePurchaseUnitOnly(item) }}
                                                      labelPosition='right'
                                                      //error={submitted && (!this.assertQuantityField())}
                                              />
                                         {/*  {' '}{this.computePurchaseUnitOnly(item)} */}
                                      </div> 
                                  </OutsideClickHandler>
                                }
                              </Table.Cell>}
{/*                               {order  && order.state!='received' &&
                                <Table.Cell width={2} textAlign='right' secondary verticalAlign='middle' onClick={(event) => this.editQuantityHandlerInput(event,index,item)}>
                                {(!editCell || (editCell && index != editCellIndex) ) && (item.data.quantity_unit != "") &&
                                    <a href='#'><FormattedNumber value={Number(item.data.quantity_value).toFixed(2)}/>{' '}{item.data.quantity_unit}</a>}
                                {(!editCell || (editCell && index != editCellIndex) ) && (item.data.quantity_unit == "") &&
                                    <a href='#'><FormattedNumber value={Number(item.data.quantity_value).toFixed(2)}/></a>}
                                {editCell && index == editCellIndex &&
                                <OutsideClickHandler onOutsideClick={(event) => this.updateQuantityOnBlur(event,order.id,item)}>
                                    <Form>
                                        <Form.Group widths='equal'>
                                            <Form.Input type="text"
                                                    id="quantity"
                                                    value={newQuantity}
                                                    onChange={this.onNewQuantity}
                                                    onKeyPress={(event) => this.updateQuantityOnEnter(event,order.id,item)} 
                                                    autoFocus
                                                    style={{height:'2.5em',minWidth:'3em',maxWidth:'7em'}}
                                            />
                                        <Form.Dropdown key={index} 
                                                    //button //basic
                                                    options={this.getRelevantOptions(item.data.ingredient.unit)}
                                                    value={newUnit}
                                                    onChange={this.onNewUnit}
                                                    style={styles.ButtonGreenNoMarginOrder}
                                        />
                                        </Form.Group>
                                    </Form> 
                                </OutsideClickHandler>
                                }  
                              </Table.Cell>} */}
                              {order  && order.state!='received' &&
                              <Table.Cell width={2} textAlign='right'>{optimizeDecimalPart(Number(item.data.ingredient.stock_quantity))} {item.data.ingredient.stock_unit}</Table.Cell>
                              }
                              {(tax > 0) &&
                              <Table.Cell width={1} textAlign='center'> <FormattedNumber value={item.data.ingredient.tax} maximumFractionDigits="2" style="percent"/></Table.Cell>}
                              {(tax <= 0) &&
                              <Table.Cell width={1} textAlign='center'> - </Table.Cell>}
                              <Table.Cell width={2} textAlign='center'>
                                  <FormattedNumber value={(item.data.item_cost*item.count).toFixed(2)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                                  {/*  {intl.formatMessage({id:'currency.symbol'})} */}</Table.Cell>
                              <Table.Cell width={1} textAlign='right'>
                              {order  && order.state!='received' &&
                                      <Popup trigger={<Icon color='grey' name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                                              onClick={(event) => this.deleteIngredientClickHandler(event,index,item)}/>
                                                      }
                                              content={<FormattedMessage id="orderslistdetails.popup.delete.text"
                                                                      defaultMessage='Delete'/>}
                                              inverted
                                              size='small' 
                                              /> }    
                              </Table.Cell>
                              {order && order.state!='received' &&
                                  <Modal id={index}
                                      open={this.state.activeModalIngredientDelete === index}
                                      dimmer='default' 
                                      size='tiny' 
                                      onClose={this.hideModal}
                                      style={styles.Modal}
                                      closeIcon>
                                      <Header icon='trash alternate outline' content={<FormattedMessage id="orderslistdetails.modal.ingredient.deletion.title"
                                                                                                          defaultMessage='Deletion confirmation'/>} />
                                      <Modal.Content>
                                          <p><FormattedMessage id="orderslistdetails.modal.ingredient.deletion.message"
                                                              defaultMessage='Really sure to delete {ingredient} from order {order} ?'
                                                              values={{order: order.ref_name,
                                                                      ingredient : item.data.ingredient.name}} /></p>
                                      </Modal.Content>
                                      <Modal.Actions>
                                          <Button style={styles.ButtonGrey}//basic color='grey' 
                                                  onClick={this.hideModal}>
                                                  <FormattedMessage id="orderslistdetails.modal.ingredient.deletion.button.no"
                                                                  defaultMessage='No'/>
                                              </Button>
                                              <Button style={styles.ButtonRed}//basic color='teal' 
                                                      labelPosition='right' 
                                                      content={<FormattedMessage id="orderslistdetails.modal.ingredient.deletion.button.yes"
                                                                              defaultMessage='Yes'/> }
                                                      icon='checkmark' 
                                                      onClick={(event) =>this.deleteIngredientOrder(event,order.id)}/>
                                      </Modal.Actions>
                                  </Modal> }
                          </Table.Row>]);
                }else{  
                    return (
                            <Table.Row key={index} >
{/*                                 <Table.Cell width={1} textAlign='center' style={{'color': 'black'}} >
                                {1 && <Input type='checkbox'
                                             //onChange={(e) => this.onChangeReceivedState(e,item)}
                                             style={{'transform': 'scale(1.2)'}}
                                             checked={1}/>}
                                </Table.Cell> */}
                                <Table.Cell width={3} textAlign='left' style={{'color': 'black'}} >
                                {item.data.ingredient.category && 
                                     <span style={{'color':'#b5b1b1','font-size':'11px'}}><i>{' '}{item.data.ingredient.category.name}{''}</i></span>}
                                <div>
                                {item.data.ingredient.name.length >= 45 && 
                                   <a href="#" 
                                   onClick={(event) => this.seeIngredient(event,item.data.ingredient.id)}
                                   >{item.data.ingredient.name.substring(0,45) + '..'}
                                </a>}
                                {item.data.ingredient.name.length < 45 && 
                                    <a href="#" 
                                   onClick={(event) => this.seeIngredient(event,item.data.ingredient.id)}
                                   >{item.data.ingredient.name}
                                </a>} 
                                </div>
                                </Table.Cell>
                                <Table.Cell width={2} textAlign='center'>{this.computeSupplier(item)}{/* {item.data.ingredient.supplier && item.data.ingredient.supplier.name} */}</Table.Cell>
                                <Table.Cell width={2} textAlign='center'>{this.computePurchaseUnit(item)}</Table.Cell>
                                
                                {order  && order.state =='received' &&
                                <Table.Cell width={2} textAlign='center'>
                                    {this.computeQuantityWithIngredientUnit(item)}
                                </Table.Cell>
                                }
                                {order  && order.state!='received' &&
                                <Table.Cell width={2} textAlign='center' onClick={(event) => this.editQuantityHandlerInput(event,index,item)}>

                                {(!editCell || (editCell && index != editCellIndex) ) &&
                                        <a href='#'>{this.computeQuantityWithIngredientUnit(item)}</a>}
                                {editCell && index == editCellIndex &&
                                <OutsideClickHandler onOutsideClick={(event) => this.updateQuantityOnBlur(event,order.id,item)}>
                                        <div>
                                           <Icon name='minus circle'  size='large' 
                                              style={{cursor:'pointer'}} 
                                              onClick={(event) =>this.onIncreaseDecreaseQuantity(event,item,false)} />
                                            <Icon name='plus circle'  size='large'
                                              style={{cursor:'pointer',padding:'0em 1em 0em 0em'}} 
                                              onClick={(event) => this.onIncreaseDecreaseQuantity(event,item,true)}/>
                                                <Input type="text"
                                                        id="quantity"
                                                        value={newQuantity}
                                                        onChange={this.onNewQuantity}
                                                        //onBlur={(event) => this.updateQuantityOnBlur(event,order.id,item)} 
                                                        onKeyPress={(event) => this.updateQuantityOnEnter(event,order.id,item)} 
                                                        autoFocus
                                                        //style={{height:'2.5em',minWidth:'5em',maxWidth:'8em', padding:'0em 0.5em 0em 0.5em'}}
                                                        style={{height:'2.6em',minWidth:'6em',maxWidth:'6em'}}
                                                        label={{ basic: false, content: this.computePurchaseUnitOnly(item)}}
                                                        labelPosition='right'
                                                        //error={submitted && (!this.assertQuantityField())}
                                                />
                                           {/*  {' '} {this.computePurchaseUnitOnly(item)} */}
                                        </div>
                                    </OutsideClickHandler>
                                }  
                            
                                </Table.Cell>}
 {/*                               {order  && order.state!='received' &&
                                <Table.Cell width={2} textAlign='right' secondary verticalAlign='middle' onClick={(event) => this.editQuantityHandlerInput(event,index,item)}>
                                {(!editCell || (editCell && index != editCellIndex) ) && (item.data.quantity_unit != "") &&
                                    <a href='#'><FormattedNumber value={Number(item.data.quantity_value).toFixed(2)}/>{' '}{item.data.quantity_unit}</a>}
                                {(!editCell || (editCell && index != editCellIndex) ) && (item.data.quantity_unit == "") &&
                                    <a href='#'><FormattedNumber value={Number(item.data.quantity_value).toFixed(2)}/></a>}
                                {editCell && index == editCellIndex &&
                                <OutsideClickHandler onOutsideClick={(event) => this.updateQuantityOnBlur(event,order.id,item)} >
                                    <Form>
                                        <Form.Group widths='equal'>
                                            <Form.Input type="text"
                                                    id="quantity"
                                                    value={newQuantity}
                                                    onChange={this.onNewQuantity}
                                                    onKeyPress={(event) => this.updateQuantityOnEnter(event,order.id,item)} 
                                                    autoFocus
                                                    style={{height:'2.5em',minWidth:'3em',maxWidth:'7em'}}
                                            />
                                        <Form.Dropdown key={index} 
                                                    //button //basic
                                                    options={this.getRelevantOptions(item.data.ingredient.unit)}
                                                    value={newUnit}
                                                    onChange={this.onNewUnit}
                                                    style={styles.ButtonGreenNoMarginOrder}
                                        />
                                        </Form.Group>
                                    </Form> 
                                </OutsideClickHandler>
                                }  
                                 </Table.Cell>}  */}
                                {order  && order.state!='received' &&
                                <Table.Cell width={2} textAlign='right'>{optimizeDecimalPart(Number(item.data.ingredient.stock_quantity))} {item.data.ingredient.stock_unit}</Table.Cell>
                                }
                                {tax > 0 &&
                                <Table.Cell width={1} textAlign='center'> <FormattedNumber value={item.data.ingredient.tax} maximumFractionDigits="2" style="percent"/></Table.Cell>}
                                {tax <= 0 &&
                                <Table.Cell width={1} textAlign='center'> - </Table.Cell>}
                                <Table.Cell width={2} textAlign='center'>
                                    <FormattedNumber value={(item.data.item_cost*item.count).toFixed(2)} style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/> 
                                    {/*  {intl.formatMessage({id:'currency.symbol'})} */}</Table.Cell>
                                <Table.Cell width={1} textAlign='right'>
{/*                                 {order  && order.state!='received' &&
                                        <Popup trigger={<Icon color='grey' name='checkbox' style={{cursor:'pointer'}} size='large'
                                                                onClick={(event) => this.deleteIngredientClickHandler(event,index,item)}/>
                                                        }
                                                content={<FormattedMessage id="orderslistdetails.popup.delete.text"
                                                                        defaultMessage='Delete'/>}
                                                inverted
                                                size='small' 
                                                /> }   */}                     
                                {order  && order.state!='received' &&
                                        <Popup trigger={<Icon color='grey' name='trash alternate outline' style={{cursor:'pointer'}} size='large'
                                                                onClick={(event) => this.deleteIngredientClickHandler(event,index,item)}/>
                                                        }
                                                content={<FormattedMessage id="orderslistdetails.popup.delete.text"
                                                                        defaultMessage='Delete'/>}
                                                inverted
                                                size='small' 
                                                /> }    
                                </Table.Cell>
                                {order && order.state!='received' &&
                                    <Modal id={index}
                                        open={this.state.activeModalIngredientDelete === index}
                                        dimmer='default' 
                                        size='tiny' 
                                        onClose={this.hideModal}
                                        style={styles.Modal}
                                        closeIcon>
                                        <Header icon='trash alternate outline' content={<FormattedMessage id="orderslistdetails.modal.ingredient.deletion.title"
                                                                                                            defaultMessage='Deletion confirmation'/>} />
                                        <Modal.Content>
                                            <p><FormattedMessage id="orderslistdetails.modal.ingredient.deletion.message"
                                                                defaultMessage='Really sure to delete {ingredient} from order {order} ?'
                                                                values={{order: order.ref_name,
                                                                        ingredient : item.data.ingredient.name}} /></p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button style={styles.ButtonGrey}//basic color='grey' 
                                                    onClick={this.hideModal}>
                                                    <FormattedMessage id="orderslistdetails.modal.ingredient.deletion.button.no"
                                                                    defaultMessage='No'/>
                                                </Button>
                                                <Button style={styles.ButtonRed}//basic color='teal' 
                                                        labelPosition='right' 
                                                        content={<FormattedMessage id="orderslistdetails.modal.ingredient.deletion.button.yes"
                                                                                defaultMessage='Yes'/> }
                                                        icon='checkmark' 
                                                        onClick={(event) =>this.deleteIngredientOrder(event,order.id)}/>
                                        </Modal.Actions>
                                    </Modal> }
                            </Table.Row>
                        );
                    }
            });
        });
    }


    upgradeSubscription = () => {
        history.push({pathname: '/profilepremium', state: {toggled: false}})
    }

    hideModal() {
        this.setState({ activeModalPremium: false });
        this.setState({ activeModalDelete: false });
        this.setState({ activeModalStatus: false });
        this.setState({ activeModalReduceStockAvailable: false });
        this.setState({ activeModalRestock: false });
        this.setState({ activeModalDestock: false });
        this.setState({ activeModalIngredientDelete: false });
        this.setState({ activeModalRename: false });
        this.setState({ activeModalIngredientRound: false });
        this.setState({ activeModalNote: false });
    }

    toggleItemsMenu(e){
        this.setState({sidebarToggled: !this.state.sidebarToggled});
        this.setState({filtering: false});
        this.setState({nameSelected: ""});
        this.setState({supplierSelected: null});
    } 

    addIngredientsRows(ingredients){
        const {currency,supplierSelected} = this.state;
        return ingredients.map((ingredient,index) => {

            let default_supplier = ingredient.supplier && ingredient.supplier.name
            // Use default supplier
            if (!ingredient.default_supplier){
                  if (ingredient.extra_suppliers){
                        for (let s of ingredient.extra_suppliers){
                                if (s.default_supplier){
                                    default_supplier = s.supplier.name
                                    break
                                }
                            }
                        }
            }

            return(
                    <Table.Row key={index} textAlign='left' style={{'backgroundColor':'#fcfcfc'}} 
                            /* onClick={(event)=>this.addIngredientToCartList(event,ingredient)} */>
                        <Table.Cell width={5} textAlign='left'>
                             <Icon name='shopping basket' color='teal' />
                             <a style={{color:'black',fontWeight:'bolder'}}>
                                {ingredient.name.length >= 45 && 
                                    ingredient.name.substring(0,45) + '..'}
                                    {ingredient.name.length < 45 && 
                                    ingredient.name}
                                 {/* {ingredient.name.substr(0, 60)} */}
                             </a>
                             <p style={{'marginLeft':'2em'}}>
                                    <span style={{'marginLeft':'0em', 'color':'#969696'}}>
{/*                                     <FormattedNumber value={ingredient.price_per_unit} style="currency" maximumFractionDigits="3" currencyDisplay="symbol" currency={currency}/> {' /'}
                                    {ingredient.purchase_quantity !== "" &&
                                        <FormattedMessage id="order.quantity.unit.text"
                                                          defaultMessage='unit'/>}
                                    {((ingredient.purchase_volume != "" || ingredient.purchase_weight != "")) && 
                                        <FormattedMessage id="order.row.input.ingredients.popup.unit.other.text"
                                                          defaultMessage='{unit}'
                                                          values={{unit:ingredient.unit}}/>} */}
                                   <FormattedNumber value={ingredient.stock_quantity} maximumFractionDigits="3"/>{' '}{ingredient.unit}{' '} 
                                    <FormattedMessage id="orderslistdetails.row.input.ingredients.popup.stock.text"
                                                          defaultMessage='in stock'/>
                                   <span style={{'marginLeft':'2em','color':'#969696'}}>{/* {'  -  '} */}
                                        {supplierSelected && supplierSelected.name}
                                        {!supplierSelected && <span>{default_supplier}</span>}
                                        </span>
                                 </span>
                             </p>
                        </Table.Cell>
                        <Table.Cell width={1} textAlign='right'>
                                <Popup trigger=
                                            {<Icon color='teal' name="add" style={{cursor:'pointer'}}  size='large' pointing='bottom left'
                                                        onClick={(event)=>this.addIngredientToCartList(event,ingredient,false)}/>
                                            }
                                            content={<FormattedMessage id="orderslistdetails.row.input.ingredients.popup.pack.text"
                                                                            defaultMessage='Add pack'/>}
                                            inverted />
                                    <Popup trigger=
                                            {<Icon color='teal' name="box" style={{cursor:'pointer'}}  size='large'  pointing='bottom right'
                                            onClick={(event)=>this.addIngredientToCartList(event,ingredient,true)}/>
                                            }
                                            content={<FormattedMessage id="orderslistdetails.row.input.ingredients.popup.box.text"
                                                                            defaultMessage='Add box'/>}
                                            inverted />
                                    
                        </Table.Cell>
                    </Table.Row>

            );
        })
    }

    addIngredientToCartList (event,ingredient,is_box_pack) {
        event.preventDefault();
        const {intl,dispatch,order} = this.props;
        const {supplierSelected} = this.state;
        var found = this.state.ingredientsList.find(x => x.id == ingredient.id)
       
        if (!found){

             let use_supplier = false;
             let supplier_id = -1;

             if (supplierSelected){
                use_supplier = true;
                supplier_id = supplierSelected.id
             }

             dispatch(recipesActions.addOrderIngredient(order.id,
                                                        {ingredient_id:ingredient.id,
                                                         use_supplier:use_supplier,
                                                         supplier_id:supplier_id,
                                                         is_box_pack: is_box_pack  }));
        }else{
            toaster_info(intl.formatMessage({ id: 'orders.toaster.ingredient.add.duplicate.message.title' }),
                         intl.formatMessage({ id: 'orders.toaster.ingredient.add.duplicate.message.content' }))
        }    
    }

    filterNameList(event){
        event.preventDefault();
        const {availableIngredientsList,} = this.state;
        this.setState({nameSelected: event.target.value});
        this.setState({supplierSelected: null});

        let updatedList = availableIngredientsList.filter((item) => {
                return (item.name).normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()
                            .search(event.target.value.normalize('NFKD').replace(/[^\w]/g, '').toLowerCase()) !== -1;
              });
        this.setState({newItems: updatedList});
        this.setState({filtering: true}); 

    }

    filterIngredientsSupplierList2(event,data){
        event.preventDefault();
        const {availableIngredientsList} = this.state;
        const {supplier} = this.props;
        let supplier_id = data.value;
        let elem = supplier.options.find(supplier => supplier.value == supplier_id) 

        let updatedList = availableIngredientsList.filter((item) => {
                let ingredient_return = null
                if (elem && item.supplier && item.supplier.id ){
                    if (item.supplier.id == supplier_id){
                        ingredient_return = item
                    }
                    if (ingredient_return) return ingredient_return
                  }

                if (elem && item.extra_suppliers && item.extra_suppliers.length > 0){
                    let extras = item.extra_suppliers
                    for (let s of extras){
                        if (s.supplier){
                            if (s.supplier.id == supplier_id){
                                ingredient_return = item
                            }
                            if (ingredient_return) return ingredient_return
                        }
                    }
                }
                return null;
            });

        if (elem){
            this.setState({ supplierSelected: {id:supplier_id,
                                              name: elem.text } });
            this.setState({filtering: true});
        }else{
            this.setState({ supplierSelected:null})
            this.setState({filtering: false});
        }

        this.setState({newItems: updatedList});
        this.setState({nameSelected: ""});
    }


    onCheckEmailSupplier = (e,data) => {
        e.preventDefault();
        this.setState({ withEmailSupplier: data.checked });
    }

    OnEmailCostsChange = (e,{ value }) => { 
        e.preventDefault();
        this.setState({ withEmailCosts: value });
    }

    isUnSubscribedUser(){
        const { subscription } = this.props;
        if (subscription && !isEmpty(subscription) && (subscription.active == false) && (subscription.bypass == false)){
            return true
        }
        return false
    }

    render() { 
        const { alert,dispatch, loading, updating,adding,customer,intl,order,supplier} = this.props;
        const {recipesData,ingredientsList,currency,suppliers_lists,numberSuppliers,availableIngredientsList,
            sidebarToggled,newItems,filtering,isMobile,nameSelected,supplierSelected,orderName,withEmailSupplier,roundingMode,withEmailCosts,orderNote } = this.state;
        let membership = null;
        let user = null;
        let isLimited = this.isUnSubscribedUser()
        let items = [];
        let className = "floating-menu";

        if (customer){
             membership = customer.membership;
             user = customer.user;
        }

        if (filtering){
            items = newItems
        }else{
            items = availableIngredientsList
        }
        //Filter and notify restrictions if user unsubscribed
        if (isLimited && items){
            items = items.slice(0,MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER);
        }

        return ( 
            <div>
                <Sidebar as={Segment}
                                animation={'overlay'}
                                direction={'right'}
                                visible={sidebarToggled}
                                vertical
                                width= {isMobile?'wide':'very wide'}
                                icon='labeled'
                                style={{'backgroundColor':'#fcfcfc'/*,'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'*/}}
                                >
                            <Grid textAlign='center' /*style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-vertical;'}}*/>
                                <Grid.Row columns={1}>
                                    <Grid.Column /*mobile={16} tablet={12} computer={8}*/ fluid>
                                        <div style={{display: 'inline-block;',position:'absolute'}}>
                                                <Icon name='close' size='large' color='grey'  onClick={(e) => this.toggleItemsMenu(e)}/>
                                        </div>  
                                        <Form>
                                        <Form.Field align='left' style={{'margin':'2em 0em 1em 1em'}}>
                                            <Header as='h2' style={{'marginLeft': '1em'}}>
                                                <img src={img_bag} style={{width:'45px'}}/>
                                            <Header.Content>
                                                <FormattedMessage id="orderslistdetails.form.input.ingredient.title"
                                                                    defaultMessage='Add ingredients'/>
                                            </Header.Content>
                                        </Header>
                                        </Form.Field>
                                        <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
{/*                                             <p style={styles.FontLato13}>
                                                    <FormattedMessage id="order.form.input.search.title"
                                                                    defaultMessage='Search' />
                                            </p> */}
                                                <FormattedMessage id="orderslistdetails.form.input.search.placeholder"
                                                            defaultMessage="Enter a name ...">
                                                {placeholder => 
                                                        <Input
                                                            fluid
                                                            //onChange={this.filterIngredientsNameList}
                                                            onChange={this.filterNameList}
                                                            color='teal'
                                                            size='large'
                                                            icon='filter'
                                                            placeholder={placeholder}
                                                            value={nameSelected}
                                                            disabled = {isLimited}
                                                            style={{'backgroundColor':'#f5f5f5'}}
                                                            //onFocus={(e)=>this.onFocusElsewhere(e)}
                                                        />
                                                } 
                                                </FormattedMessage> 
                                        </Form.Field>
                                        <Form.Field align='left' style={{'margin':'0em 0.5em 1em 0.5em'}}>
                                                <span>
                                                    <p style={styles.FontLato13}>
                                                        <FormattedMessage id="orderslistdetails.form.filter.supplier.title"
                                                                        defaultMessage='Supplier' />
                                                    </p>
                                                    <FormattedMessage id="orderslistdetails.form.filter.supplier.placeholder"
                                                                        defaultMessage='Select a supplier'>
                                                        {placeholder =>   
                                                            <Dropdown selection
                                                                    options= {supplier && supplier.options} 
                                                                    value= {supplierSelected && supplierSelected.id}
                                                                    placeholder={placeholder} 
                                                                    onChange={this.filterIngredientsSupplierList2}
                                                                    clearable
                                                                    fluid
                                                                    disabled = {isLimited}
                                                                    //style={{minWidth:'18em',maxWidth:'26em'}}
                                                                    />}   
                                                    </FormattedMessage>
                    {/*                                                     <Button basic icon='remove' 
                                                            onClick={this.removeIngredientsFilters}>
                                                    </Button> */}
                                                </span>
                                        </Form.Field>
                                        {!supplierSelected &&  
                                            <Form.Field align='left' style={{'margin':'1em 1em 1em 1em'}}>
                                                <span style={{'color':'#009b9b'}}>
                                                    <FormattedMessage id="orderslistdetails.form.filter.supplier.default.title"
                                                                        defaultMessage='Default supplier is used'/>
                                            </span>
                                            </Form.Field>
                                        }
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={1}>
                                   { (adding) &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Grid.Column /*mobile={16} tablet={16} computer={16}*/ fluid >
                                        <Table  unstackable 
                                                singleLine 
                                                style={styles.FontLato13Border}
                                                //style={{'overflow-y': 'hidden','overflow-x': 'auto;','overflow': '-moz-scrollbars-none;','-ms-overflow-style':'none;'}}
                                                >
                                                <Table.Body>
                                                {availableIngredientsList && availableIngredientsList.length > 0 && items &&
                                                    this.addIngredientsRows(items)
                                                }
                                                {availableIngredientsList && availableIngredientsList.length == 0 && 
                                                <Table.Row textAlign='left' style={{'backgroundColor':'#fcfcfc','font-family': 'Lato','font-size': '14px'}}>
                                                    <Table.Cell>
                                                        <FormattedMessage id="orderslistdetails.list.ingredient.empty.message"
                                                                            defaultMessage='No ingredient created yet' />
                                                    </Table.Cell>
                                                    <Table.Cell width={1} textAlign='right'>
                                                        <Button color='teal' circular  className="ui small icon button" 
                                                                onClick={this.createIngredient}>
                                                                <i className="add icon"></i>
                                                        </Button>
                                                    </Table.Cell>
                                                </Table.Row>
                                                }
                                            </Table.Body>
                                            {isLimited && items &&
                                                <Table.Footer /*fullWidth*/ width={4}>
                                                        <Table.HeaderCell colSpan='2'>
                                                        <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                                <Image src={img_performance} size ='tiny' rounded/>
                                                                <br/>
                                                                <Message.Content style={{marginLeft:'1em'}}>
                                                                    <Message.Header color='black'>
                                                                        <FormattedMessage id="orderslistdetails.list.premium.title.message.upgradeplan"
                                                                                            defaultMessage='Recipes/Ingredients limit exceeded'/> 
                                                                    </Message.Header>
                                                                    <br/>
                                                                    <div style={styles.FontLato13}>
                                                                        <FormattedMessage id="orderslistdetails.list.premium.message.upgradeplan.line1"
                                                                                        defaultMessage='Your have'/>{' '}{availableIngredientsList.length}{' '}
                                                                        <FormattedMessage id="orderslistdetails.list.premium.message.upgradeplan.line3"
                                                                                        defaultMessage='ingredients but limited to'/>{' '}{MAX_NUMBER_INGREDIENTS_FREE_SUBSCRIBER}{'.'} 
                                                                    </div>
                                                                    <br/><br/>
                                                                    <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                                        <FormattedMessage id="orderslistdetails.list.premium.button.chooseplan"
                                                                                    defaultMessage='Choose premium plan'/>
                                                                    </Button>                                      
                                                                </Message.Content>
                                                            </Message>
                                                        </Table.HeaderCell>
                                            </Table.Footer>}
                                    </Table>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Sidebar>
                     <SideLeftUncoverOrders>
                        <Grid stackable id='grid'  style={styles.FontLato13Border}>
                            <Grid.Row columns={1}>
                             <Breadcrumb  style={{background:'#fcfcfc'}}>
                                <Breadcrumb.Section link as={Link} to={'/dashboard/'}><FormattedMessage id="orderslistdetails.link.dashboard"
                                                              defaultMessage='Dashboard'/></Breadcrumb.Section>
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section link as={Link} to={'/ordersList/'}><FormattedMessage id="orderslistdetails.link.orders"
                                                              defaultMessage='Orders'/></Breadcrumb.Section> 
                                <Breadcrumb.Divider icon='right chevron'/>
                                <Breadcrumb.Section active>{order && order.ref_name}</Breadcrumb.Section>                                
                             </Breadcrumb>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column width={14}>
                                        <Header as='h2' fluid>
                                            <img src={img_order} style={{width:'50px'}}/>
                                            <Header.Content>
                                                <FormattedMessage id="orderslistdetails.page.title"
                                                                defaultMessage='Order'/>{' '}{/* <FormattedMessage id="orderslistdetails.page.order.status.ongoing"
                                                                defaultMessage='ongoing'/> */}
                                                {order && (!order.state) &&
                                                    <FormattedMessage id="orderslistdetails.page.order.status.ongoing"
                                                                    defaultMessage='ongoing'/>
                                                }                  
                                                {order && order.state &&
                                                    <span>
                                                        {order.state == 'ongoing' &&
                                                            <FormattedMessage id="orderslistdetails.page.order.status.ongoing"
                                                                    defaultMessage='ongoing'/>}
                                                        {order.state == 'received' &&
                                                            <FormattedMessage id="orderslistdetails.page.order.status.received"
                                                                    defaultMessage='received'/>
                                                                    }
                                                        {order.state == 'sent' &&
                                                            <FormattedMessage id="orderslistdetails.page.order.status.sent"
                                                                    defaultMessage='sent'/>}
                                                    </span>
                                                }  
                                                <Header.Subheader  style={{'font-size': '14px','color': '#66b2b2', display: 'flex'}} 
                                                                   //contenteditable="true" 
                                                                   //onBlur={(event) => this.updateOrderName(event)} 
                                                                   >
                                                        <FormattedMessage id="orderslistdetails.page.order.caption.name"
                                                                           defaultMessage='Ref:'/>&nbsp;{orderName}&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <Popup trigger= {  <Icon color='grey'
                                                                                name='pencil alternate'
                                                                                //size='medium' 
                                                                                disabled = {loading||updating}
                                                                                style={{cursor:'pointer'}}
                                                                                onClick={(event)=>this.renameClickHandler(event)}
                                                                            />}
                                                                    content={<FormattedMessage id="orderslistdetails.order.caption.icon.title"
                                                                    defaultMessage='Rename'/>}
                                                                    position='bottom center'
                                                                    inverted
                                                            />
                                                            <Modal open={this.state.activeModalRename}
                                                                dimmer='default' 
                                                                size='tiny'
                                                                style={styles.Modal} 
                                                                onClose={this.hideModal}>
                                                                <Header icon='pencil alternate' content={<FormattedMessage id="orderslistdetails.order.modal.rename.title"
                                                                                                                           defaultMessage='Rename order'/>} />
                                                                <Modal.Content>
                                                                    <Input
                                                                        type="text"
                                                                        placeholder="Renaming"
                                                                        id="renameCategory"
                                                                        value={this.state.nameSelected}
                                                                        onChange={this.onRenameOrder}
                                                                        style={{minWidth:'20em',maxWidth:'40em'}}
                                                                        />
                                                                </Modal.Content>
                                                                <Modal.Actions>
                                                                    <Button color='grey' onClick={this.hideModal}>
                                                                        <FormattedMessage id="orderslistdetails.order.modal.rename.button.no"
                                                                                        defaultMessage='Cancel'/>
                                                                    </Button>
                                                                    <Button color='teal' 
                                                                        labelPosition='right' 
                                                                        content={<FormattedMessage id="orderslistdetails.order.modal.rename.button.yes"
                                                                                                    defaultMessage='Rename'/>}
                                                                        icon='checkmark' 
                                                                        onClick={(event) =>this.renameOrder(event)}/>
                                                                    </Modal.Actions>
                                                            </Modal> 
                                                </Header.Subheader>
                                            </Header.Content>
                                         </Header>
                                </Grid.Column>
                            </Grid.Row>
{/*                             {alert.message && 
                                <Grid.Row><div className={`alert ${alert.type}`}>{alert.message}</div></Grid.Row>
                            } */}
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            <Grid.Row>
                                <Grid.Column>
                                  <span>
                                            <Header as='h4' /* color='grey' */>
                                              {order && order.created && 
                                                <span style={{color: 'grey'}}><FormattedMessage id="orderslistdetails.list.info.date"
                                                              defaultMessage='Created' />{' '}
                                            
                                                <span style={{marginLeft:'1em',color: 'grey'}}>
                                                         <FormattedDate value={new Date(order.created)}
                                                                                   year='numeric'
                                                                                   month='long'
                                                                                   day='2-digit'/>{' - '}
                                                         <FormattedTime value={new Date(order.created)}
                                                                                   hour='numeric'
                                                                                   minute='numeric'/>
                                                </span></span>}
                                                <br/>
                                                {order && order.received && 
                                                <span style={{color: 'black'}}><FormattedMessage id="orderslistdetails.list.info.date.received"
                                                                  defaultMessage='Received' />{' '}
                                                <span style={{marginLeft:'1em',color: 'black'}}>
                                                         <FormattedDate value={new Date(order.received)}
                                                                                   year='numeric'
                                                                                   month='long'
                                                                                   day='2-digit'/>{' - '}
                                                         <FormattedTime value={new Date(order.received)}
                                                                                   hour='numeric'
                                                                                   minute='numeric'/>
                                                </span></span>}
                                                </Header>
                                 </span>
                                 {/*</Grid.Column>
                            </Grid.Row>
                           <Grid.Row columns={1}/> */}
                            <br/>
                            {order &&
                                 <span> 
                                    <Segment raised size='small' style={{  'max-width':'520px','min-height':'15px;',cursor:'pointer'}}
                                                                 onClick={(event)=>this.noteClickHandler(event)}>
                                                        {orderNote.length == 0 && 
                                                                <FormattedMessage id="orderslistdetails.page.order.note.update"
                                                                                defaultMessage='Add comment/note'/> 
                                                        }
                                                        { orderNote.split('\n').map( line => 
                                                             <div  key={ Math.random() * 10}  dir="auto"  style={{textAlign: 'start'}}> {line} </div> 
                                                        )} 
                                                         <br/>
                                                       </Segment>
                                                        <Modal open={this.state.activeModalNote}
                                                                dimmer='default' 
                                                                size='tiny'
                                                                style={styles.Modal} 
                                                                onClose={this.hideModal}>
                                                                <Header content={<FormattedMessage id="orderslistdetails.order.modal.note.title"
                                                                                                   defaultMessage='Update note'/>} />
                                                                <Modal.Content>
                                                                    <TextArea
                                                                        type="text"
                                                                        placeholder="Note"
                                                                        id="note"
                                                                        rows='4'
                                                                        maxlength="650"
                                                                        value={this.state.note}
                                                                        onChange={this.onNoteUpdate}
                                                                        style={{ minHeight: 160, minWidth:500,resize:'none'}}/>
                                                                </Modal.Content>
                                                                <Modal.Actions>
                                                                    <Button color='grey' onClick={this.hideModal}>
                                                                        <FormattedMessage id="orderslistdetails.order.modal.note.button.no"
                                                                                        defaultMessage='Cancel'/>
                                                                    </Button>
                                                                    <Button color='teal'
                                                                        labelPosition='right' 
                                                                        content={<FormattedMessage id="orderslistdetails.order.modal.note.button.yes"
                                                                                                    defaultMessage='Update'/>}
                                                                        icon='checkmark' 
                                                                        onClick={(event) =>this.updateOrder(event)}/>
                                                                    </Modal.Actions>
                                                            </Modal> 
                                 </span>}
                                </Grid.Column>
                            </Grid.Row>
                            <br/>
                            {order && (!order.ingredients_added) && order.items && (order.items.length>0) && <Grid.Row columns={1} style={{'overflow-x': 'auto'}}>
                                <Grid.Column  mobile={18} tablet={16} computer={14}> 
                                 {loading &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> }
                                    <Table selectable 
                                           unstackable 
                                           compact 
                                           color={'blue'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={4} textAlign='left'>
                                                    <FormattedMessage id="orderslistdetails.list.requested.table.column1"
                                                                     defaultMessage='Recipe quantity' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={5} textAlign='left'>
                                                    <FormattedMessage id="orderslistdetails.list.requested.table.column2"
                                                                     defaultMessage='Recipe Name' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={5} textAlign='center'>
                                                    <FormattedMessage id="orderslistdetails.list.requested.table.column3"
                                                                     defaultMessage='Recipe yield' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign='right'>
                                                    <FormattedMessage id="orderslistdetails.list.requested.table.column4"
                                                                     defaultMessage='Unit price' />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                        {recipesData &&
                                            this.addOrderRows(recipesData)
                                        }                              
                                        </Table.Body>
                                    </Table>
                                    <br/>
                                </Grid.Column>         
                              </Grid.Row>} 
{/*                             <Grid.Row>
                                <Grid.Column>
                                    <br/><br/> <Label basic style={styles.FontLato13Bold} size='big' color='blue'>
                                            <FormattedMessage id="orderslistdetails.shopping.list.requested.title"
                                                              defaultMessage='Your shopping list' />
                                    </Label>
                                </Grid.Column>
                            </Grid.Row> */}
                            <Grid.Row columns={3}/>
                            <Grid.Row columns={3}>
                                <Grid.Column mobile={8} tablet={4} computer={4}/>
                                <Grid.Column mobile={5} tablet={0} computer={6}/>
                                <Grid.Column mobile={3} tablet={16} computer={4}> 
                                 <div className={className}>
                                   {order && order && order.state!='received'  && 
                                       <Popup trigger=
                                            { <Dropdown button 
                                                                    className= 'icon'
                                                                    icon = 'ellipsis vertical'
                                                                    floating
                                                                    labeled
                                                                    text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.round"
                                                                                            defaultMessage='Round' />}
                                                                    //basic
                                                                    style={styles.ButtonGreyAddNoMarginOrder}
                                                                    >
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                            icon ='level up'
                                                                            onClick={(event)=>this.roundIngredientClickHandler(event,false)}
                                                                            text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.roundup"
                                                                                                    defaultMessage='To upper unit' />}
                                                                    >
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        icon = 'box'
                                                                        onClick={(event)=>this.roundIngredientClickHandler(event,true)}
                                                                        text = {<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.roundpack"
                                                                                                defaultMessage='To purchase pack (or box)' />}
                                                                    >
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                            </Dropdown>}
                                            content={<FormattedMessage id="orderslistdetails.floatingmenu.round.title"
                                                                        defaultMessage='Round quantities'/>}
                                            //position='up center'
                                            inverted
                                    />}
                                  <Popup trigger=
                                            { <Button //circular 
                                                      color='teal'
                                                      style={styles.ButtonGreenAddNoMarginOrder}
                                                      //size='big' 
                                                      //icon='shopping basket' 
                                                      disabled = {loading||updating}
                                                      onClick={(e)=>this.toggleItemsMenu(e,'ingredient')}>
                                                     <Icon name='add circle' />{' '}
                                                     <FormattedMessage id="orderslistdetails.floatingmenu.ingredients.icon.title"
                                                                       defaultMessage='Ingredients'/>
                                               </Button>}
                                             content={<FormattedMessage id="orderslistdetails.floatingmenu.ingredients.title"
                                                                        defaultMessage='Add ingredients'/>}
                                            //position='up center'
                                            inverted
                                    />
                                 </div>
                                </Grid.Column>
                                {order && order.state!='received' &&
                                  <Modal open={this.state.activeModalIngredientRound === true}
                                        dimmer='default' 
                                        size='tiny' 
                                        onClose={this.hideModal}
                                        style={styles.Modal}
                                        closeIcon>
                                        <Header icon='sync alternate' content={<FormattedMessage id="orderslistdetails.modal.ingredient.round.title"
                                                                                                            defaultMessage='Rounding order quantities'/>} />
                                        <Modal.Content>

                                            {!roundingMode && <p><FormattedMessage id="orderslistdetails.modal.ingredient.roundup.message"
                                                                defaultMessage='Really sure to round up to the next whole number all items quantities in order {order} ?'
                                                                values={{order: order.ref_name}} /></p>}
                                            {roundingMode &&<p><FormattedMessage id="orderslistdetails.modal.ingredient.roundsupplier.message"
                                                                defaultMessage='Really sure to round up to supplier purchase pack weight all items quantities in order {order} ?'
                                                                values={{order: order.ref_name}} /></p>}
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button style={styles.ButtonGrey}//basic color='grey' 
                                                    onClick={this.hideModal}>
                                                    <FormattedMessage id="orderslistdetails.modal.ingredient.round.button.no"
                                                                    defaultMessage='Cancel'/>
                                                </Button>
                                                {roundingMode &&<Button style={styles.ButtonGreyAddNoMarginOrder}//basic color='teal' 
                                                        labelPosition='right' 
                                                        content={<FormattedMessage id="orderslistdetails.modal.ingredient.round.button.yes"
                                                                                defaultMessage='Confirm'/> }
                                                        icon='checkmark' 
                                                        onClick={(event) =>this.roundIngredientsOrder(event,true)}/>}
                                                 {!roundingMode &&<Button style={styles.ButtonGreyAddNoMarginOrder}//basic color='teal' 
                                                        labelPosition='right' 
                                                        content={<FormattedMessage id="orderslistdetails.modal.ingredient.round.button.yes"
                                                                                defaultMessage='Confirm'/> }
                                                        icon='checkmark' 
                                                        onClick={(event) =>this.roundIngredientsOrder(event,false)}/>}
                                        </Modal.Actions>
                                    </Modal> }
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column  /*width={14}*/  mobile={18} tablet={16} computer={14}> 
                                 <Dimmer.Dimmable> 
                                        <Dimmer active={loading||updating||adding} blurring inverted>
                                           <Loader inverted content='Loading' />
                                        </Dimmer>
                                    <Table id='table'
                                           //selectable 
                                           unstackable 
                                           //compact 
                                           //striped 
                                           singleLine 
                                           //color={'blue'}
                                           style={styles.FontLato13Border}>
                                        <Table.Header>
                                            <Table.Row>
{/*                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="orderslistdetails.shopping.list.requested.table.column0"
                                                                        defaultMessage='Received' />
                                                </Table.HeaderCell> */}
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    <FormattedMessage id="orderslistdetails.shopping.list.requested.table.column1"
                                                                        defaultMessage='Ingredient' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="orderslistdetails.shopping.list.requested.table.column5"
                                                                        defaultMessage='Supplier' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="orderslistdetails.shopping.list.requested.table.column2"
                                                                     defaultMessage='Purchase Unit' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="orderslistdetails.shopping.list.requested.table.column3"
                                                                     defaultMessage='Amount' />
                                                </Table.HeaderCell>
                                                {order  && order.state!='received' &&
                                                <Table.HeaderCell width={2} textAlign='right'>
                                                    <FormattedMessage id="orderslistdetails.shopping.list.requested.table.column6"
                                                                        defaultMessage='Stock Quantity' />
                                                </Table.HeaderCell>}
                                                <Table.HeaderCell width={1} textAlign='center'>
                                                    <FormattedMessage id="orderslistdetails.shopping.list.requested.table.column7"
                                                                            defaultMessage='Tax %' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={2} textAlign='center'>
                                                    <FormattedMessage id="orderslistdetails.shopping.list.requested.table.column4"
                                                                        defaultMessage='Cost' />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='right'/>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
{/*                                         {ingredientsList &&
                                                this.addListRows(ingredientsList)
                                        } */}
                                        {suppliers_lists &&
                                            this.addListRowsPerSupplier()
                                        }
                                        </Table.Body>
                                        <Table.Footer>
                                            <Table.Row>
{/*                                                <Table.HeaderCell width={3} textAlign='left'/> */}
                                                <Table.HeaderCell width={3} textAlign='left'>
                                                    {/* <Label basic style={styles.FontLato14Bold} size='large' color='grey'>
                                                        <FormattedMessage id="orderslistdetails.shopping.list.requested.table.text.total"
                                                                          defaultMessage='TOTAL' />
                                                    </Label> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell/>
                                                <Table.HeaderCell width={3} textAlign='center'>
{/*                                                     <Button basic icon
                                                        labelPosition='left' 
                                                        
                                                        color='teal'
                                                        style={styles.FontLato14Bold}
                                                        onClick={this.saveOrder}><Icon name='save'/>
                                                            <FormattedMessage id="orderrxesult.shopping.list.requested.table.button.save"
                                                                             defaultMessage='Save Order' />
                                                    </Button> */}
{/*                                                     <Popup trigger=
                                                        {<Button basic 
                                                                icon 
                                                                color='red' 
                                                                labelPosition='left'
                                                                size='medium'
                                                                style={styles.FontLato14Bold}
                                                                onClick={(event) => this.deleteClickHandler(event)}>
                                                                <Icon name='trash icon'/>
                                                                <FormattedMessage id="orderslistdetails.list.items.button.delete"
                                                                             defaultMessage='Delete Order' />
                                                        </Button>}
                                                        //header='Delete Recipe details'
                                                        content={<FormattedMessage id="ordersresultdetails.popup.delete.text"
                                                                                defaultMessage='Delete'/>}
                                                        inverted/> */}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={3} textAlign='center'>
{/*                                                     <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='teal'
                                                        style={styles.FontLato14Bold}
                                                        onClick={this.generatePDFBackend}><Icon name='print'/>
                                                            <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.print"
                                                                             defaultMessage='Print PDF' />
                                                    </Button> */}
                                                </Table.HeaderCell>
                                                {order  && order.state!='received' &&
                                                <Table.HeaderCell width={3} textAlign='center'/>}
                                              {/*   <Table.HeaderCell width={1} textAlign='right'/> */}
                                              <Table.HeaderCell width={1} textAlign='center'>
                                                    <p style={{'font-size': '16px','margin': '0','padding': '0' }} >
                                                        <FormattedMessage id="orderslistdetails.shopping.list.requested.table.text.total.ht"
                                                                          defaultMessage='Total cost'/>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </p>
                                                    <p style={{'font-size': '12px','margin': '0','padding': '0' }} >
                                                        <FormattedMessage id="orderslistdetails.shopping.list.requested.table.text.total.tva"
                                                                          defaultMessage='Tax'/>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </p>
                                                    <p style={{'font-size': '18px','font-weight':'bold','margin': '0','padding': '0' }} >
                                                        <FormattedMessage id="orderslistdetails.shopping.list.requested.table.text.total"
                                                                          defaultMessage='Total tax incl.'/>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </p>
                                                </Table.HeaderCell> 
                                                <Table.HeaderCell width={3} textAlign='center'>
{/*                                                     <Label basic style={styles.FontLato12Bold} size='large' color='blue'>
                                                         {ingredientsList &&
                                                                <FormattedNumber value={this.computeTotalCost(ingredientsList)} 
                                                                      style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                         }
                                                    </Label> */}
                                                    <p style={{'font-size': '16px','margin': '0','padding': '0'  }} >
                                                        <FormattedNumber value={this.computeTotalCost(ingredientsList)} 
                                                                      style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                    </p>
                                                    <p style={{'font-size': '12px','margin': '0','padding': '0'  }} >
                                                        <FormattedNumber value={this.computeTotalTax(ingredientsList)} 
                                                                      style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                    </p>
                                                    <p style={{'font-size': '18px','font-weight':'bold','margin': '0','padding': '0'  }} >
                                                        <FormattedNumber value={this.computeTotalCostWithTax(ingredientsList)} 
                                                                      style="currency" maximumFractionDigits="2" currencyDisplay="symbol" currency={currency}/>
                                                    </p>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell width={1} textAlign='right'/>
                                                {order &&
                                                <Modal open={this.state.activeModalDelete}
                                                    dimmer='true' 
                                                    size='tiny' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='trash alternate outline' content={<FormattedMessage id="orderslistdetails.modal.deletion.title"
                                                                                                    defaultMessage='Deletion confirmation'/>} />
                                                    <Modal.Content>
                                                        <p><FormattedMessage id="orderslistdetails.modal.deletion.message"
                                                                            defaultMessage='Really sure to delete the order {name} ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="orderslistdetails.modal.deletion.button.no"
                                                                                defaultMessage='No'/>
                                                        </Button>
                                                        <Button style={styles.ButtonRed}//basic color='teal' 
                                                            labelPosition='right' 
                                                            content={<FormattedMessage id="orderslistdetails.modal.deletion.button.yes"
                                                                                        defaultMessage='Yes'/> }
                                                            icon='checkmark' 
                                                            onClick={(event) =>this.deleteOrder(event,order.id)}/>
                                                    </Modal.Actions>
                                                </Modal> }
                                                {order &&
                                                <Modal open={this.state.activeModalStatus}
                                                    dimmer='true' 
                                                    size='small' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='send' color='blue' content={<FormattedMessage id="orderslistdetails.modal.status.title"
                                                                                                    defaultMessage='Your order is going to be moved to status SENT'/>} />
                                                    <Modal.Content>
                                                    <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}>
                                                        <FormattedMessage id="orderslistdetails.modal.status.message"
                                                                            defaultMessage='Really sure to update state of order {name} ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    
                                                    {suppliers_lists && suppliers_lists.filter(s => s.supplier_name !='undefined').length === 1 && 
                                                           <Segment raised secondary>
                                                                <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}>
                                                                {(membership && user && ((membership.membership_type=="Entreprise") || (user.bypass==true))) &&
                                                                    <FormattedMessage id="orderslistdetails.modal.mail.checkbox"
                                                                            defaultMessage="Sent this order by email to my supplier">
                                                                    {text => 
                                                                        <Checkbox   label={text}
                                                                                    onChange={this.onCheckEmailSupplier}
                                                                                    checked={withEmailSupplier}  
                                                                                    
                                                                                    />}
                                                                    </FormattedMessage>}
                                                                {(membership && user && ((membership.membership_type!="Entreprise") && (user.bypass==false))) &&
                                                                <span>
                                                                    <FormattedMessage id="orderslistdetails.modal.mail.checkbox"
                                                                            defaultMessage="Sent this order by email to my supplier">
                                                                    {text => 
                                                                        <Checkbox   label={text}
                                                                                    onChange={this.onCheckEmailSupplier}
                                                                                    checked={withEmailSupplier}  
                                                                                    disabled
                                                                                    />}
                                                                    </FormattedMessage> 
                                                                    <span style={{marginLeft: '3em'}}><Label color='blue' onClick={(e) => this.upgradeSubscription()} style={{'cursor': 'pointer'}}>
                                                                        <FormattedMessage id="orderslistdetails.modal.option.list.premium"
                                                                                          defaultMessage='ENTERPRISE option'/>
                                                                    </Label></span></span>
                                                                }
                                                                </p>
                                                                {withEmailSupplier &&
                                                                <div>
                                                                <p style={{'margin-top': '-20px',paddingLeft:'3em', 'font-size': '12px', 'font-family': 'Lato' }}>
                                                                <FormattedMessage id="orderslistdetails.modal.option.costs"
                                                                     defaultMessage='Products costs INCLUDED in order file'>
                                                                    {label => 
                                                                        <Radio
                                                                            label={label}
                                                                            name='radioGroup'
                                                                            value={1}
                                                                            checked={withEmailCosts == 1}
                                                                            onChange={this.OnEmailCostsChange}
                                                                        />}
                                                                    </FormattedMessage>
                                                                 </p>
                                                                 <p style={{'margin-top': '-20px', paddingLeft:'3em','font-size': '12px', 'font-family': 'Lato' }}>
                                                                  <FormattedMessage id="orderslistdetails.modal.option.nocosts"
                                                                     defaultMessage='Products costs NOT INCLUDED in order file'>
                                                                    {label => 
                                                                        <Radio
                                                                            label={label}
                                                                            name='radioGroup'
                                                                            value={0}
                                                                            checked={withEmailCosts == 0}
                                                                            onChange={this.OnEmailCostsChange}
                                                                        />}
                                                                    </FormattedMessage>
                                                                </p></div>}
                                                            </Segment>
                                                    }
                                                    {suppliers_lists && suppliers_lists.filter(s => s.supplier_name !='undefined').length > 1 && 
                                                            <Segment raised secondary>
                                                                <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}>
{/*                                                                 <FormattedMessage id="orderslistdetails.modal.mail.checkbox"
                                                                        defaultMessage="Sent this order by email to my supplier">
                                                                {text => 
                                                                    <Checkbox   label={text}
                                                                                onChange={this.onCheckEmailSupplier}
                                                                                //checked={withEmailSupplier} 
                                                                                disabled 
                                                                                />}
                                                                </FormattedMessage> */}
                                                                <Icon name='info circle' color='teal' size='large'/>
                                                                <FormattedMessage id="orderslistdetails.modal.mail.checkbox.notpossible"
                                                                        defaultMessage="To send this order by email to each supplier, click cancel and then on create supplier orders."/>
                                                                </p>
                                                            </Segment>
                                                    }
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="orderslistdetails.modal.status.button.no"
                                                                                defaultMessage='Cancel'/>
                                                        </Button>
                                                        <Button style={styles.ButtonBlue}//basic color='teal' 
                                                                labelPosition='right' 
                                                                content={<FormattedMessage id="orderslistdetails.modal.status.button.yes"
                                                                                          defaultMessage='Proceed'/> }
                                                            icon='checkmark' 
                                                            onClick={this.setOrderStatus}/>
                                                    </Modal.Actions>
                                                </Modal> }
                                                {order &&
                                                <Modal open={this.state.activeModalReduceStockAvailable}
                                                    dimmer='true' 
                                                    size='small' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='box' color='blue' content={<FormattedMessage id="orderslistdetails.modal.reducestock.title"
                                                                                                    defaultMessage='Your order quantities are going to be reduced from inventory existing quantities'/>} />
                                                    <Modal.Content>
                                                        <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}><FormattedMessage id="orderslistdetails.modal.reducestock.message"
                                                                            defaultMessage='Really sure to decrease stock good available quantities from {name} ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="orderslistdetails.modal.reducestock.button.no"
                                                                                defaultMessage='Cancel'/>
                                                        </Button>
                                                        <Button style={styles.ButtonBlue}//basic color='teal'
                                                                labelPosition='right' 
                                                                loading = {updating}
                                                                content={<FormattedMessage id="orderslistdetails.modal.reducestock.button.yes"
                                                                                          defaultMessage='Proceed'/> }
                                                            icon='checkmark' 
                                                            onClick={this.reduceStockQuantity}/>
                                                    </Modal.Actions>
                                                </Modal> }
                                                {order &&
                                                <Modal open={this.state.activeModalDestock}
                                                    dimmer='true' 
                                                    size='small' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='box' color='blue' content={<FormattedMessage id="orderslistdetails.modal.destock.title"
                                                                                                    defaultMessage='Your order quantities are going to be removed from inventory'/>} />
                                                    <Modal.Content>
                                                        <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}><FormattedMessage id="orderslistdetails.modal.destock.message"
                                                                            defaultMessage='Really sure to destock order quantities from {name} ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="orderslistdetails.modal.destock.button.no"
                                                                                defaultMessage='Cancel'/>
                                                        </Button>
                                                        <Button style={styles.ButtonBlue}//basic color='teal'
                                                                labelPosition='right' 
                                                                loading = {updating}
                                                                content={<FormattedMessage id="orderslistdetails.modal.destock.button.yes"
                                                                                          defaultMessage='Proceed'/> }
                                                            icon='checkmark' 
                                                            onClick={this.destockQuantity}/>
                                                    </Modal.Actions>
                                                </Modal> }
                                                {order &&
                                                <Modal open={this.state.activeModalRestock}
                                                    dimmer='true' 
                                                    size='small' 
                                                    onClose={this.hideModal}
                                                    style={styles.Modal}
                                                    closeIcon>
                                                    <Header icon='box' color='blue' content={<FormattedMessage id="orderslistdetails.modal.restock.title"
                                                                                                    defaultMessage='Order items are going to be added to your stock inventory'/>} />
                                                    <Modal.Content >
                                                        <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}><FormattedMessage id="orderslistdetails.modal.restock.message.advise"
                                                                            defaultMessage='At this step, be careful to double check your delivery form of goods before proceeding (edit and adjust order quantities if necessary). Order in status RECEIVED is not editable.'
                                                                            values={{name: order.ref_name}} /></p>
                                                        <p style={{paddingLeft:'0.5em', color:'black',  'font-size': '16px', 'font-family': 'Lato' }}><FormattedMessage id="orderslistdetails.modal.restock.message"
                                                                            defaultMessage='Really sure to update your order to state received ?'
                                                                            values={{name: order.ref_name}} /></p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button style={styles.ButtonGrey}//basic color='grey' 
                                                                onClick={this.hideModal}>
                                                                <FormattedMessage id="orderslistdetails.modal.restock.button.no"
                                                                                defaultMessage='Cancel'/>
                                                        </Button>
                                                        <Button style={styles.ButtonBlue}//basic color='teal'
                                                                labelPosition='right' 
                                                                loading = {updating}
                                                                content={<FormattedMessage id="orderslistdetails.modal.restock.button.yes"
                                                                                          defaultMessage='Proceed'/> }
                                                            icon='checkmark' 
                                                            onClick={this.addStockQuantity}/>
                                                    </Modal.Actions>
                                                </Modal> }
                                                <Modal open={this.state.activeModalPremium}
                                                    dimmer='true' 
                                                    style={styles.Modal} 
                                                    onClose={this.hideModal}
                                                    closeIcon>
                                                <Modal.Header>
                                                    <FormattedMessage id="orderslistdetails.printorder.header.title"
                                                                    defaultMessage='Print Order'/>
                                                </Modal.Header>
                                                <Modal.Content>
                                                    <Message fluid icon size='big' style={styles.FontLato12Bold} color='blue'>
                                                    <Image src={img_performance} size ='small' rounded/>
                                                    <br/>
                                                    <Message.Content style={{marginLeft:'1em'}}>
                                                        <Message.Header color='black'>
                                                            <FormattedMessage id="orderslistdetails.premium.message.upgradeplan"
                                                                                defaultMessage='PREMIUM Feature'/>
                                                        </Message.Header>
                                                        <br/>
                                                        <FormattedMessage id="orderslistdetails.premium.title.message.upgradeplan"
                                                                        defaultMessage='Your current plan: '/>{' '}{membership && membership.name}   
                                                        <br/><br/>
                                                        <Button basic color='blue' style={styles.FontLato12Bold} onClick={(e) => this.upgradeSubscription()}>
                                                            <FormattedMessage id="orderslistdetails.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                        </Button>                                      
                                                    </Message.Content>
                                                </Message>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button basic color='grey' onClick= {(e) => this.hideModal()}>
                                                    {/* <Icon name='exit' /> */}
                                                    <FormattedMessage id="orderslistdetails.premium.button.cancel"
                                                                    defaultMessage='Cancel'/>
                                                    </Button>
                                                    <Button color='blue' onClick= {(e) => this.upgradeSubscription()}>
                                                    <FormattedMessage id="orderslistdetails.premium.button.chooseplan"
                                                                        defaultMessage='Choose premium plan'/>
                                                    </Button>
                                                </Modal.Actions>
                                            </Modal> 
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                    </Dimmer.Dimmable>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}/>
                            {/* <Grid.Row columns={1}/> */}
                             <Grid.Row columns={1}>
                               {!loading && <Grid.Column computer={14} 
                                            tablet={16} 
                                            style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} 
                                            only='computer tablet'>
                                              {order && order.state!='received' && (order.use_ingredients == true) &&  
                                              <Button basic 
                                                            icon 
                                                            labelPosition='left' 
                                                            color='blue' 
                                                            size='medium'
                                                            style={styles.FontLato14BoldPadded}
                                                            onClick={(event) => this.orderStatusClickHandler(event)}>
                                                            <Icon name='send'/> 
                                                            {order && (!order.state || order.state=='ongoing') &&
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.send"
                                                                             defaultMessage='Send' />}
                                                            {order && order.state=='sent' &&
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.receive"
                                                                             defaultMessage='Receive' />}
                                              </Button>}
                                              {order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) &&
                                              <Dropdown button 
                                                        className= 'icon'
                                                        icon = 'ellipsis vertical'
                                                        floating
                                                        labeled
                                                        text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.actions.stock"
                                                                                defaultMessage='Inventory Actions' />}
                                                        basic
                                                        style={styles.FontLato14BoldPadded}>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                                icon ='box'
                                                                onClick={(event) => this.reduceStockClickHandler(event)}
                                                                text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.deduce"
                                                                                        defaultMessage='Reduce already existing in stock' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'boxes'
                                                              onClick={(event) => this.destockClickHandler(event)}
                                                              text = {<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.destock"
                                                                                    defaultMessage='Destock order' />}
                                                        >
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                              </Dropdown>}
{/*                                               {order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) &&
                                              <Button basic 
                                                            icon 
                                                            labelPosition='left' 
                                                            color='white' 
                                                            size='medium'
                                                            onClick={(event) => this.reduceStockClickHandler(event)}
                                                            style={styles.FontLato14BoldPadded}><Icon name='box'/> 
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.deduce"
                                                                             defaultMessage='Reduce already existing in stock' />
                                             </Button>}
                                             {order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) &&
                                              <Button basic 
                                                            icon 
                                                            labelPosition='left' 
                                                            color='white' 
                                                            size='medium'
                                                            onClick={(event) => this.destockClickHandler(event)}
                                                            style={styles.FontLato14BoldPadded}><Icon name='boxes'/> 
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.destock"
                                                                             defaultMessage='Destock order' />
                                             </Button>} */}
                                             {order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) && numberSuppliers>1 &&
                                               <Button basic
                                                            icon 
                                                            labelPosition='left' 
                                                            color='white' 
                                                            size='medium'
                                                            onClick={(event) => this.createSuppliersOrders(event)}
                                                            style={styles.FontLato14BoldPadded}><Icon name='shipping fast'/> 
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.convertSuppliers"
                                                                               defaultMessage='Create {number} suppliers orders'
                                                                               values={{number: numberSuppliers}} />
                                             </Button>}
{/*                                              <Dropdown button 
                                                        className= 'icon'
                                                        icon = 'ellipsis vertical'
                                                        floating
                                                        labeled
                                                        text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.round"
                                                                                defaultMessage='Round' />}
                                                        basic
                                                        style={styles.FontLato14BoldPadded}>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                                icon ='level up'
                                                                onClick={(event)=>this.roundUpIngredientOrder(event)}
                                                                text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.roundup"
                                                                                        defaultMessage='Round all to upper quantities' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'shopping bag'
                                                              onClick={(event)=>this.roundPurchaseIngredientOrder(event,false)}
                                                              text = {<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.roundpack"
                                                                                    defaultMessage='Round all to purchase quantities' />}
                                                        >
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown> */}
                                                <Dropdown button 
                                                        className= 'icon'
                                                        icon = 'ellipsis vertical'
                                                        floating
                                                        labeled
                                                        text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.print"
                                                                                defaultMessage='Export' />}
                                                        basic
                                                        style={styles.FontLato14BoldPadded}>
                                                    <Dropdown.Menu>
                                                    <DropdownHeader content={<FormattedMessage id="orderslistdetails.shopping.list.requested.header.menu.cost"
                                                                                              defaultMessage='With costs'/>} />
                                                        <Dropdown.Item
                                                                icon ='file pdf'
                                                                onClick={(event)=>this.generateDocBackend(event,true,1)}
                                                                text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.exportpdf"
                                                                                        defaultMessage='PDF File' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'file excel'
                                                              onClick={(event)=>this.generateDocBackend(event,false,1)}
                                                              text = {<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.exportcsv"
                                                                                    defaultMessage='CSV File' />}
                                                        >
                                                        </Dropdown.Item>
                                                    <DropdownDivider />
                                                    <DropdownHeader content={<FormattedMessage id="orderslistdetails.shopping.list.requested.header.menu.nocost"
                                                                                              defaultMessage='Without costs'/>} />
                                                      <Dropdown.Item
                                                              icon ='file pdf'
                                                              onClick={(event)=>this.generateDocBackend(event,true,0)}
                                                              text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.exportpdf"
                                                                                      defaultMessage='PDF File' />}
                                                      >
                                                      </Dropdown.Item>
                                                      <Dropdown.Item
                                                            icon = 'file excel'
                                                            onClick={(event)=>this.generateDocBackend(event,false,0)}
                                                            text = {<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.exportcsv"
                                                                                  defaultMessage='CSV File' />}
                                                      >
                                                      </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Button basic 
                                                                icon 
                                                                color='white' 
                                                                labelPosition='left'
                                                                size='medium'
                                                                style={styles.FontLato14BoldPadded}
                                                                onClick={(event) => this.deleteClickHandler(event)}>
                                                                <Icon name='trash alternate outline icon'/>
                                                                <FormattedMessage id="orderslistdetails.list.items.button.delete"
                                                                             defaultMessage='Delete' />
                                                </Button>
                                               <br/>
                                    </Grid.Column>} 
                               {!loading && order && order.state!='received' && (order.use_ingredients == true) &&         
                               <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                             <br/> 
                                              <Button basic 
                                                            icon 
                                                            labelPosition='left' 
                                                            color='blue' 
                                                            size='medium'
                                                            onClick={(event) => this.orderStatusClickHandler(event)}
                                                            style={styles.FontLato14BoldPadded}><Icon name='send'/> 
                                                            {(!order.state || order.state=='ongoing') &&
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.send"
                                                                             defaultMessage='Send' />}
                                                            {order.state=='sent' &&
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.receive"
                                                                             defaultMessage='Receive' />}
                                              </Button>
                              </Grid.Column>}
{/*                               {!loading && order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) &&
                              <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                              <Button basic 
                                                            icon 
                                                            labelPosition='left' 
                                                            color='white' 
                                                            size='medium'
                                                            onClick={(event) => this.reduceStockClickHandler(event)}
                                                            style={styles.FontLato14BoldPadded}><Icon name='box'/> 
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.deduce"
                                                                             defaultMessage='Reduce already existing in stock' />
                                              </Button>
                              </Grid.Column>}
                              {!loading && order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) &&
                              <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                              <Button basic 
                                                            icon 
                                                            labelPosition='left' 
                                                            color='white' 
                                                            size='medium'
                                                            onClick={(event) => this.destockClickHandler(event)}
                                                            style={styles.FontLato14BoldPadded}><Icon name='boxes'/> 
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.destock"
                                                                             defaultMessage='Destock order' />
                                              </Button>
                              </Grid.Column>} */}
                              {!loading && order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) &&
                              <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                              <Dropdown button 
                                                        className= 'icon'
                                                        icon = 'ellipsis vertical'
                                                        floating
                                                        labeled
                                                        text='Actions Stock'
                                                        basic
                                                        style={styles.FontLato14BoldPadded}>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                                icon ='box'
                                                                onClick={(event) => this.reduceStockClickHandler(event)}
                                                                text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.deduce"
                                                                                        defaultMessage='Reduce already existing in stock' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'boxes'
                                                              onClick={(event) => this.destockClickHandler(event)}
                                                              text = {<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.destock"
                                                                                    defaultMessage='Destock order' />}
                                                        >
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                              </Dropdown>
                              </Grid.Column>}
                               {!loading && order && (!order.state || order.state=='ongoing') && (order.use_ingredients == true) && numberSuppliers > 1 &&
                              <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                              <Button basic
                                                            icon 
                                                            labelPosition='left' 
                                                            color='white' 
                                                            size='medium'
                                                            onClick={(event) => this.createSuppliersOrders(event)}
                                                            style={styles.FontLato14BoldPadded}><Icon name='shipping fast'/> 
                                                             <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.convertSuppliers"
                                                                               defaultMessage='Create {number} suppliers orders'
                                                                               values={{number: numberSuppliers}} />
                                             </Button>
                              </Grid.Column>}
                              {!loading && <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
{/*                                               <Button basic icon
                                                        labelPosition='left' 
                                                        size='medium'
                                                        color='white'
                                                        style={styles.FontLato14BoldPadded}
                                                        onClick={this.generatePDFBackend}><Icon name='print'/>
                                                            <FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.print"
                                                                             defaultMessage='Export' />
                                                </Button> */}
                                                <Dropdown button 
                                                        className= 'icon'
                                                        icon = 'ellipsis vertical'
                                                        floating
                                                        labeled
                                                        text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.print"
                                                                                defaultMessage='Export' />}
                                                        basic
                                                        style={styles.FontLato14BoldPadded}>
                                                    <Dropdown.Menu>
                                                    <DropdownHeader content={<FormattedMessage id="orderslistdetails.shopping.list.requested.header.menu.cost"
                                                                                              defaultMessage='With costs'/>} />
                                                        <Dropdown.Item
                                                                icon ='file pdf'
                                                                onClick={(event)=>this.generateDocBackend(event,true,1)}
                                                                text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.exportpdf"
                                                                                        defaultMessage='PDF File' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                              icon = 'file excel'
                                                              onClick={(event)=>this.generateDocBackend(event,false,1)}
                                                              text = {<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.exportcsv"
                                                                                    defaultMessage='CSV File' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <DropdownDivider />
                                                        <DropdownHeader content={<FormattedMessage id="orderslistdetails.shopping.list.requested.header.menu.nocost"
                                                                                                defaultMessage='Without costs'/>} />
                                                        <Dropdown.Item
                                                                icon ='file pdf'
                                                                onClick={(event)=>this.generateDocBackend(event,true,0)}
                                                                text={<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.exportpdf"
                                                                                        defaultMessage='PDF File' />}
                                                        >
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                                icon = 'file excel'
                                                                onClick={(event)=>this.generateDocBackend(event,false,0)}
                                                                text = {<FormattedMessage id="orderslistdetails.shopping.list.requested.table.button.exportcsv"
                                                                                    defaultMessage='CSV File' />}
                                                        >
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                              </Dropdown>
                              </Grid.Column>}
                              {!loading && <Grid.Column  mobile={18} only='mobile' style={{'text-align':'center','justify-content': 'center',margin: '0 auto;',display: 'flex;'}} >
                                                <Button basic 
                                                                icon 
                                                                color='white' 
                                                                labelPosition='left'
                                                                size='medium'
                                                                style={styles.FontLato14BoldPadded}
                                                                onClick={(event) => this.deleteClickHandler(event)}>
                                                                <Icon name='trash alternate outline icon'/>
                                                                <FormattedMessage id="orderslistdetails.list.items.button.delete"
                                                                             defaultMessage='Delete' />
                                                </Button>
                                                <br/> 
                                </Grid.Column> }                                               
                            </Grid.Row>
                            <Grid.Row columns={1}/> 
                        </Grid>
                     </SideLeftUncoverOrders>
                </div>
        )
    }
}

function mapStateToProps(state) {
    const { alert,dispatch,recipes,ingredients,supplier } = state;
    const { customer } = state.payment;
    const { loading,updating,adding,order } = state.recipes;
    const { subscription } = state.payment;

    return {
        loading,
        adding,
        updating,
        recipes,
        ingredients,
        supplier,
        order,
        customer,
        alert,
        dispatch,
        subscription,
    };
}

const connectedOrdersListDetails = injectIntl(connect(mapStateToProps)(OrdersListDetails));
export { connectedOrdersListDetails as OrdersListDetails };
 
